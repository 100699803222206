import * as React from 'react';
import { CustomInput } from '@zerintia/powerstone-ui';

import { FilterProps } from './FilterProps.interface';

export const BooleanColumnFilter: React.FC<FilterProps> = ({
  column: { filterValue, setFilter, id, filterProps },
}) => {
  const {
    afirmativeLabel,
    all = 'All',
    defaultValue,
    negativeLabel,
    onChange,
  } = filterProps || {};
  const [inputValue, setInputvalue] = React.useState(filterValue);
  const options = [
    {
      value: true,
      label: afirmativeLabel || 'TRUE',
    },
    {
      value: false,
      label: negativeLabel || 'FALSE',
    },
  ];
  return (
    <CustomInput
      id={id}
      bsSize='sm'
      type='select'
      value={inputValue}
      onChange={(e) => {
        onChange?.(e.target.value);
        setFilter(e.target.value);
        setInputvalue(e.target.value || undefined);
      }}
      defaultValue={defaultValue}
    >
      <option value=''>{all}</option>
      {options.map((option, index) => (
        <option key={index} value={option.value as any}>
          {option.label}
        </option>
      ))}
    </CustomInput>
  );
};
