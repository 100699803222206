import * as React from 'react';
import { AxiosResponse } from 'axios';

export function useApiQuery(
  api: any,
  findFn: (...params: any) => Promise<AxiosResponse>
) {
  const [response, setResponse] = React.useState({
    loading: false,
    data: {},
    error: undefined,
  });

  const executeFindFn = React.useCallback(function(...params: any[]) {
    setResponse({ data: {}, error: undefined, loading: true });

    return findFn
      .apply(api, [...params])
      .then((axiosResponse: AxiosResponse) => {
        setResponse({
          data: axiosResponse.data,
          loading: false,
          error: undefined,
        });
      })
      .catch((err) => {
        setResponse({ data: {}, loading: false, error: err });
      });
  }, []);

  return [
    { data: response.data, loading: response.loading, error: response.error },
    executeFindFn,
  ];
}
