import * as React from 'react';
import { Input } from '@zerintia/powerstone-ui';

import { FilterProps } from './FilterProps.interface';

const Comparator = {
  EQ: '=',
  GT: '>',
  LT: '<',
};

export const NumberColumnFilter: React.FC<FilterProps> = ({
  column: { filterValue, setFilter, filterProps },
}) => {
  const { placeholder } = filterProps || {};
  const [inputValue, setInputvalue] = React.useState(filterValue);
  const [comparator, setComparator] = React.useState(Comparator.EQ);

  return (
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <Input
        bsSize="sm"
        value={inputValue}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setInputvalue(parseInt(val, 10) || undefined);
          setFilter(
            val ? { numberValue: parseInt(val, 10), comparator } : null
          );
        }}
        placeholder={placeholder}
      />
      <Input
        style={{ width: '25%', minWidth: '3rem' }}
        type="select"
        bsSize="sm"
        onChange={(e) => {
          const comparator = e.target.value;
          setComparator(comparator);

          if (inputValue) {
            setFilter({ numberValue: inputValue, comparator });
          }
        }}
        onClick={() => {}}
        value={comparator}
        className="d-block mr-1"
        id="id"
      >
        {Object.values(Comparator).map((comparator, index) => (
          <option value={comparator} key={index}>
            {comparator}
          </option>
        ))}
      </Input>
    </div>
  );
};
