import * as React from 'react';
import { Button } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';

interface ZModalDeleteConfirmProps {
  id: string;
  onDelete: (id: string, name?: string) => void;
  text?: string | JSX.Element;
  gender: string;
  title: string;
  name: string;
  confirmLabel?: string | JSX.Element;
}

const ZModalDeleteConfirm: React.FC<ZModalDeleteConfirmProps> = ({
  id,
  onDelete,
  text,
  gender,
  title,
  name,
  confirmLabel,
}) => {
  const { t } = useTranslation('translations');

  const handleSubmit = () => {
    onDelete(id, name);
  };

  return (
    <>
      <div>
        {text || (
          <Trans
            i18nKey={gender}
            values={{
              item: t(title).toLowerCase(),
              name: name,
            }}
            components={{ strong: <strong /> }}
          />
        )}
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button color="danger" type="button" onClick={handleSubmit}>
          <>{confirmLabel || t('DELETE')}</>
        </Button>
      </div>
    </>
  );
};

export default ZModalDeleteConfirm;
