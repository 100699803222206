const sessionInfoProps = {
  USER: 'user',
  SITES: 'sites',
  LOGIN_TYPES: 'login_types',
  END_POINT: 'end_points',
  WEB_PATH: 'web_path',
  SOCKET_PATH: 'socket_path',
  PROFILE: 'profile',
  STREAMING_END_POINT: 'streaming_end_point',
  STREAMING_WEB_PATH: 'streaming_web_path',
  STREAMING_SOCKET_PATH: 'streaming_socket_path',
  RESOURCES: 'resources',
};
interface resultProps {
  end_point: string | null;
  path: string | null;
  socket_path: string | null;
}
const sessionStorageService = {
  /**
   * Stores a user in session storage
   * Then, that info will be stored in sessionStorage
   * @param {object} user the user that will be stored in session
   */
  setUser(user: any) {
    sessionStorage.setItem(sessionInfoProps.USER, JSON.stringify(user));
  },

  /**
   * Retireves the session storage user
   * @return {object} the session user
   */
  getUser() {
    const stringifiedUser = sessionStorage.getItem(sessionInfoProps.USER);
    return stringifiedUser ? JSON.parse(stringifiedUser) : null;
  },

  /**
   * Stores the selectable sites of the application in sessionStorage
   * @param {array.<object>} sites a list of selectable sites
   */
  setSites(selectableSites: any[]) {
    sessionStorage.setItem(
      sessionInfoProps.SITES,
      JSON.stringify(selectableSites)
    );
  },

  /**
   * Retireves the session storage sites list
   * @return {object} the session sites list
   */
  getSites() {
    const stringifiedSites = sessionStorage.getItem(sessionInfoProps.SITES);
    return stringifiedSites ? JSON.parse(stringifiedSites) : null;
  },

  /**
   * Stores the available types of login of the application in sessionStorage
   * @param {array.<object>} type logins a list of selectable sites
   */
  setLoginTypes(login_types: any) {
    sessionStorage.setItem(
      sessionInfoProps.LOGIN_TYPES,
      JSON.stringify(login_types)
    );
  },

  /**
   * Retireves the session storage types login list
   * @return {object} the session types login list
   */
  getLoginTypes() {
    const login_types = sessionStorage.getItem(sessionInfoProps.LOGIN_TYPES);
    return login_types ? JSON.parse(login_types) : null;
  },
  /**
   * Stores the session endpoints login list
   * @return {object} the session types login list
   */

  setProfile(profile: Object) {
    sessionStorage.setItem(sessionInfoProps.PROFILE, JSON.stringify(profile));
  },
  /**
   * Retrieves the session endpoints login list
   * @return {object} the session types login list
   */

  getProfile() {
    return sessionStorage.getItem(sessionInfoProps.PROFILE);
  },
  /**
   * Stores the session endpoints login list
   * @return {object} the session types login list
   */

  setEndPoint(end_point: string, path: string, socket_path: string) {
    sessionStorage.setItem(sessionInfoProps.END_POINT, end_point);
    sessionStorage.setItem(sessionInfoProps.WEB_PATH, path);
    if (socket_path)
      sessionStorage.setItem(sessionInfoProps.SOCKET_PATH, socket_path);
  },

  /**
   * Stores the session endpoints login list
   * @return {object} the session types login list
   */

  setStreamingPath(end_point: string, path: string, socket_path: string) {
    sessionStorage.setItem(sessionInfoProps.STREAMING_END_POINT, end_point);
    sessionStorage.setItem(sessionInfoProps.STREAMING_WEB_PATH, path);
    if (socket_path)
      sessionStorage.setItem(
        sessionInfoProps.STREAMING_SOCKET_PATH,
        socket_path
      );
  },

  /**
   * Retrieves the session endpoints login list
   * @return {object} the session types login list
   */

  getStreamingPath() {
    let result: resultProps = {
      end_point: null,
      path: null,
      socket_path: null,
    };
    result.end_point = sessionStorage.getItem(
      sessionInfoProps.STREAMING_END_POINT
    );
    result.path = sessionStorage.getItem(sessionInfoProps.STREAMING_WEB_PATH);
    result.socket_path =
      sessionStorage.getItem(sessionInfoProps.STREAMING_SOCKET_PATH) || null;
    return result;
  },

  /**
   * @typedef EndpointSessionResult
   * @property {string} end_point endpoint domain
   * @property {string} path web path
   * @property {string} socket_pacth socket path
   */
  /**
   * Retrieves the session endpoints login list
   * @return {EndpointSessionResult} the session types login list
   */
  getEndPoint() {
    let result: resultProps = {
      end_point: null,
      path: null,
      socket_path: null,
    };
    result.end_point = sessionStorage.getItem(sessionInfoProps.END_POINT);
    result.path = sessionStorage.getItem(sessionInfoProps.WEB_PATH);
    result.socket_path =
      sessionStorage.getItem(sessionInfoProps.SOCKET_PATH) || null;
    return result;
  },

  /**
   * Retrieves the session data
   * @return {object} the session types login list
   */
  recover() {
    let result = {
      accessToken: sessionStorage.getItem('accessToken'),
      resetToken: sessionStorage.getItem('resetToken'),
      tokenType: sessionStorage.getItem('tokenType'),
      //this.sites = this.getSites();
      profile: this.getProfile(),
      end_point: this.getEndPoint(),
      user_sites: this.getSites(),
      pass_format_requirements: this.getLoginTypes(),
    };
    if (result?.accessToken) {
      return result;
    } else {
      return false;
    }
  },

  getResources() {
    const resources = sessionStorage.getItem(sessionInfoProps.RESOURCES);
    return resources ? JSON.parse(resources) : null;
  },

  setResources(newResources: any) {
    sessionStorage.setItem(
      sessionInfoProps.RESOURCES,
      JSON.stringify(newResources)
    );
  },
};

export default sessionStorageService;
