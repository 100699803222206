import React from 'react';
import classNames from 'classnames';

interface SidebarHideSlimProps {
  children: any;
}

export const SidebarHideSlim: React.FC<SidebarHideSlimProps> = ({
  children,
}) => {
  return React.Children.map(children, (child: any) =>
    React.cloneElement(child, {
      className: classNames(child.props.className, 'sidebar__hide-slim'),
    })
  );
};
