import React, { useState } from 'react';
import moment, { Moment } from 'moment';

import {
  DateRangePicker as DateRangePickerFromReactDates,
  FocusedInputShape,
  DayOfWeekShape,
  OrientationShape,
} from 'react-dates';

import 'react-dates/initialize';

interface defaultValueprops {
  startDate?: Date;
  endDate?: Date;
}
export interface DateRangePickerProps {
  appendToBody?: boolean;
  defaultValue?: defaultValueprops;
  endDateId: string;
  endDatePlaceholderText?: string;
  firstDayOfWeek?: DayOfWeekShape | null | undefined;
  isOutsideRange?: (day: any) => boolean;
  onChange?: (startDate: Date | null, endDate: Date | null) => void;
  orientation?: OrientationShape;
  showClearDates?: boolean;
  small?: boolean;
  startDateId: string;
  startDatePlaceholderText?: string;
  displayFormat?: string;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  defaultValue,
  endDateId,
  endDatePlaceholderText,
  onChange = () => {},
  startDateId,
  startDatePlaceholderText,
  ...props
}) => {
  const [startDate, setStartDate] = useState<Moment | null>(
    defaultValue?.startDate ? moment(defaultValue.startDate) : null
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    defaultValue?.endDate ? moment(defaultValue.endDate) : null
  );
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  const handlendDatesChange = (arg: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    onChange(arg.startDate?.toDate() || null, arg.endDate?.toDate() || null);
    setStartDate(arg.startDate);
    setEndDate(arg.endDate);
  };

  return (
    <DateRangePickerFromReactDates
      {...props}
      startDatePlaceholderText={startDatePlaceholderText}
      endDatePlaceholderText={endDatePlaceholderText}
      startDate={startDate}
      startDateId={startDateId}
      endDate={endDate}
      endDateId={endDateId}
      onDatesChange={handlendDatesChange}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
    />
  );
};
