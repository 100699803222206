import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import get from 'lodash/get';
import { FormText, AsyncTypeahead } from '@zerintia/powerstone-ui';

interface ZAsyncTypeaheadProps {
  name: string;
  valueKey?: string;
  //defaultValue?: string;
  searchFunction: Function;
  innerRef?: React.LegacyRef<AsyncTypeahead<never>>;
  clearButton?: boolean;
  placeholder?: string;
  onChange?: Function;
  minLength?: number;
  labelKey: any;
  size?: 'large' | 'lg' | 'small' | 'sm';
  disabled?: boolean;
  //initialOptions?: any[];
}

const ZAsyncTypeahead: React.FC<ZAsyncTypeaheadProps> = ({
  name,
  searchFunction,
  labelKey,
  valueKey,
  //defaultValue,
  onChange = () => {},
  innerRef,
  minLength = 2,
  size,
  //initialOptions,
  clearButton,
  placeholder,
  disabled,
}) => {
  const { errors } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = async (query: string) => {
    setIsLoading(true);
    const options = await searchFunction(query);
    setOptions(options);
    setIsLoading(false);
  };

  const handleDefaultValue = (value: any) => {
    if (valueKey && value) {
      const selectedOption =
        options && options.find((item: any) => get(item, valueKey) === value);
      return selectedOption ? [selectedOption] : [];
    }

    return value ? [value] : [];
  };

  return (
    <>
      <Controller
        render={({ onChange: onFormChange, value }: any) => {
          const selectedValue = handleDefaultValue(value);
          return (
            <AsyncTypeahead
              disabled={disabled}
              placeholder={placeholder}
              clearButton={clearButton}
              size={size}
              ref={innerRef as any}
              labelKey={labelKey}
              isInvalid={Boolean(get(errors, name))}
              id={name}
              isLoading={isLoading}
              minLength={minLength}
              useCache
              filterBy={() => true}
              onSearch={handleSearch}
              options={options}
              selected={selectedValue}
              onChange={(selected: any) => {
                const result = valueKey
                  ? get(selected?.[0], valueKey)
                  : selected?.[0];
                onFormChange(result);
                onChange(selected || selected[0]);
                return selected;
              }}
            />
          );
        }}
        //defaultValue={defaultValue}
        name={name}
      />
      {get(errors, name) && (
        <FormText color="danger">{get(errors, `${name}.message`)}</FormText>
      )}
    </>
  );
};

export default ZAsyncTypeahead;
