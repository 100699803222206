import { useUserSessionState } from '../contexts/session';

interface ResourceAbility {
  canRead: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  showMenu: boolean;
  canManage: boolean;
  canView: boolean;
  canExecute: boolean;
  canInitiate: boolean;
  canPause: boolean;
  canReceive: boolean;
  CanAssign: boolean;
  canConfigure: boolean;
  canRecord: boolean;
  canPoint: boolean;
  canWorkload: boolean;
  canPlanning: boolean;
  canMultiple: boolean;
  canSignDocument: boolean;
  canSync: boolean;
  canAddUser: boolean;
  canChangeMainVideo: boolean;
  canChat: boolean;
  canControlMainAudio: boolean;
  canDraw: boolean;
  canFlashlight: boolean;
  canFreeText: boolean;
  canFreeze: boolean;
  canGridView: boolean;
  canMuteMainVideo: boolean;
  canRemoteCameraControl: boolean;
  canRemoteCapture: boolean;
  canResolution: boolean;
  canScreenshot: boolean;
  canShareScreen: boolean;
  canSlaveCamera: boolean;
  canSpeechToText: boolean;
  canZoom: boolean;
  canSessionStats: boolean;
  canPrint: boolean;
  canScan: boolean;
  canMuteOwnAudio: boolean;
  canMuteOwnVideo: boolean;
  canFullscreen: boolean;
  canLowBandwidth: boolean;
}

export function usePrivileges(resourceName: string): ResourceAbility {
  const { privileges } = useUserSessionState();
  const privilege = privileges?.[resourceName];

  return {
    canRead: privilege?.read || false,
    canCreate: privilege?.create || false,
    canUpdate: privilege?.update || false,
    canDelete: privilege?.delete || false,
    showMenu: privilege?.menu || false,
    canManage: privilege?.manage || false,
    canView: privilege?.view || false,
    canExecute: privilege?.execute || false,
    canInitiate: privilege?.initiate || false,
    canPause: privilege?.pause || false,
    canReceive: privilege?.receive || false,
    CanAssign: privilege?.assign || false,
    canConfigure: privilege?.configure || false,
    canRecord: privilege?.record || false,
    canPoint: privilege?.point || false,
    canWorkload: privilege?.workload || false,
    canPlanning: privilege?.planning || false,
    canMultiple: privilege?.multiple || false,
    canSignDocument: privilege?.signDocument || false,
    canSync: privilege?.sync || false,
    canAddUser: privilege?.addUser || false,
    canChangeMainVideo: privilege?.changeMainVideo || false,
    canChat: privilege?.chat || false,
    canControlMainAudio: privilege?.controlMainAudio || false,
    canDraw: privilege?.draw || false,
    canFlashlight: privilege?.flashlight || false,
    canFreeText: privilege?.freeText || false,
    canFreeze: privilege?.freeze || false,
    canGridView: privilege?.gridView || false,
    canMuteMainVideo: privilege?.muteMainVideo || false,
    canRemoteCameraControl: privilege?.remoteCameraControl || false,
    canRemoteCapture: privilege?.remoteCapture || false,
    canResolution: privilege?.resolution || false,
    canScreenshot: privilege?.screenshot || false,
    canShareScreen: privilege?.shareScreen || false,
    canSlaveCamera: privilege?.slaveCamera || false,
    canSpeechToText: privilege?.speechToText || false,
    canZoom: privilege?.zoom || false,
    canSessionStats: privilege?.sessionStats || false,
    canPrint: privilege?.print || false,
    canScan: privilege?.scan || false,
    canMuteOwnAudio: privilege?.muteOwnAudio || false,
    canMuteOwnVideo: privilege?.muteOwnVideo || false,
    canFullscreen: privilege?.fullscreen || false,
    canLowBandwidth: privilege?.lowBandwidth || false,
  };
}
