import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Button, FormGroup, Label } from 'reactstrap';
import { ZForm, useZForm, ZInput } from '@zerintia/powerstone-form';
import { PageLoader } from '@zerintia/powerstone-ui';
import { useToasts } from '@zerintia/powerstone-ui';
import { getApi } from '../../../../services/api';
import sessionStorageService from '../../../../services/sessionStorage';
import { useUserSessionState } from '../../../../contexts/session';

interface UserChangePasswordProps {
  userId: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  forceSubmit?: boolean;
  defaultSubmitData?: object;
}

const UserChangePassword: React.FC<UserChangePasswordProps> = ({
  userId,
  onSubmit = () => {},
  onCancel = () => {},
  forceSubmit = false,
  defaultSubmitData = {},
}) => {
  const { managementUri } = useUserSessionState();
  const { t } = useTranslation('core');
  const { showToast } = useToasts();
  const [loading, setLoading] = React.useState(false);
  const { password_requirements } = sessionStorageService.getLoginTypes() || {
    password_requirements: { min: 6, max: 12, maxseq: 3 },
  };

  const checkPasswordFormat = function (
    pwd: any,
    options: any
  ): { ok: boolean; err: string } {
    if (!pwd) return { ok: false, err: t('REQUIRED') };

    // Counts
    const min = options.hasOwnProperty('min') ? options.min : 10;
    const max = options.hasOwnProperty('max') ? options.max : 10;
    const dig = options.hasOwnProperty('dig') ? options.dig : 1;
    const upp = options.hasOwnProperty('upp') ? options.upp : 1;
    const low = options.hasOwnProperty('low') ? options.low : 1;
    const spe = options.hasOwnProperty('spe') ? options.spe : 1;
    const maxseq = options.hasOwnProperty('maxseq') ? options.maxseq : 3;

    const count = { digit: 0, upper: 0, lower: 0, special: 0 };
    let seq = 1;
    let ms = 1;

    // Length
    if (pwd.length < min)
      return { ok: false, err: t('PASSWORD_LENGTH_LESS', { min }) };
    if (pwd.length > max)
      return { ok: false, err: t('PASSWORD_LENGTH_MORE', { max }) };

    // Verify password content
    for (let i = 0; i < pwd.length; i++) {
      // Count char types
      const c = pwd.charCodeAt(i); // Char
      if (c > 47 && c < 58) count.digit++;
      // Digits
      else if (c > 64 && c < 91) count.upper++;
      // Uppercase letter
      else if (c > 96 && c < 123) count.lower++;
      // Lowercase letter
      else count.special++; // Special

      // Count consecutive chars
      if (i > 0) {
        if (c == pwd.charCodeAt(i - 1)) {
          seq++;
          if (seq > ms) ms = seq;
        } else {
          seq = 1;
        }
      }
    }

    // Check
    if (count.digit < dig)
      return {
        ok: false,
        err: t('PASSWORD_DIGITS', { dig }),
      };
    if (count.upper < upp)
      return {
        ok: false,
        err: t('PASSWORD_UPP', { upp }),
      };
    if (count.lower < low)
      return {
        ok: false,
        err: t('PASSWORD_LOW', { low }),
      };
    if (count.special < spe)
      return {
        ok: false,
        err: t('PASSWORD_SPE', { spe }),
      };
    if (ms > maxseq)
      return {
        ok: false,
        err: t('PASSWORD_CONSECUTIVE', { maxseq }),
      };

    // Ok
    return { ok: true, err: '' };
  };

  const UserChangePasswordSchema = yup.object().shape({
    oldPassword: yup.string().required(t('REQUIRED')),
    newPassword: yup
      .string()
      .test(
        'password',
        (value) => checkPasswordFormat(value.value, password_requirements).err,
        (value) => checkPasswordFormat(value, password_requirements).ok
      )
      .notOneOf([yup.ref('oldPassword')], t('PASSWORD_DIFF_OLD'))
      .required(t('REQUIRED')),
    checkingPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], t('PASSWORD_MUST_MATCH'))
      .required(t('REQUIRED')),
  });
  const [form] = useZForm({ validationSchema: UserChangePasswordSchema });

  const getErrorMessage = (err: any = {}) => {
    const { code } = err.response?.data || {};

    const errorCodes: Record<string, any> = {
      2: t('PASSWORD_CAN_NOT_BE_THE_PREVIOUS'),
      3: t('INVALID_OLD_PASSWORD'),
      default: t('ERROR_ON_PASSWORD_REGENERATION'),
    };

    return errorCodes[code] || errorCodes.default;
  };

  const regeneratePassword = async (data: Record<string, any>) => {
    setLoading(true);
    try {
      await getApi(managementUri).regeneratePassword(userId, {
        ...data,
        ...defaultSubmitData,
      });

      showToast('success', t('PASSWORD_GENERATED'));
      onSubmit();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showToast('error', t(errorMessage));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (data: Record<string, any>) => {
    const { oldPassword, newPassword, checkingPassword } = data;

    if (checkingPassword !== newPassword) {
      form.setError('checkingPassword', {
        type: 'manual',
        message: t('PASSWORD_NOT_MATCH'),
      });

      return;
    }

    regeneratePassword({
      adminUpdate: false,
      oldPassword,
      newPassword,
    });
  };

  return (
    <>
      {loading && <PageLoader />}
      <ZForm form={form} onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="oldPassword">{t('PASSWORD')}</Label>
          <ZInput
            name="oldPassword"
            placeholder={t('PASSWORD')}
            type="password"
          />
        </FormGroup>
        <div className="mb-2">
          <span>
            <i>
              {t('PASSWORD_RESTRICTIONS', {
                min: password_requirements.min,
                max: password_requirements.max,
                maxseq: password_requirements.maxseq,
              })}
            </i>
          </span>
        </div>
        <FormGroup>
          <Label htmlFor="newPassword">{t('NEW_PASSWORD')}</Label>
          <ZInput
            name="newPassword"
            placeholder={t('NEW_PASSWORD')}
            type="password"
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="checkingPassword">{t('REPEAT_PASSWORD')}</Label>
          <ZInput
            name="checkingPassword"
            placeholder={t('REPEAT_PASSWORD')}
            type="password"
          />
        </FormGroup>
        <section className="d-flex justify-content-end">
          <Button color="primary" type="submit" className="mr-2">
            {t('ACCEPT')}
          </Button>
          {!forceSubmit && (
            <Button type="button" onClick={onCancel}>
              {t('CANCEL')}
            </Button>
          )}
        </section>
      </ZForm>
    </>
  );
};

export default UserChangePassword;
