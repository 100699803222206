import React from 'react';
import classNames from 'classnames';

interface SidebarMobileFluidProps {
  className?: string;
  children?: React.ReactNode;
}

const SidebarMobileFluid: React.FC<SidebarMobileFluidProps> = (props) => {
  const wrapClass = classNames('sidebar__mobile-fluid', props.className);

  return <div className={wrapClass}>{props.children}</div>;
};

export { SidebarMobileFluid };
