import React from 'react';
import get from 'lodash/get';

import { Controller, useFormContext } from 'react-hook-form';

import { CustomInput, FormText } from '@zerintia/powerstone-ui';

interface ZSwitchInputControllerProps {
  defaultValue?: boolean;
  label: string;
  name: string;
  onChange?: Function;
}

const ZSwitchInputController: React.FC<ZSwitchInputControllerProps> = ({
  defaultValue = false,
  label,
  onChange,
  name,
  ...rest
}) => {
  const { errors } = useFormContext();

  return (
    <>
      <Controller
        defaultValue={defaultValue || false}
        name={name}
        render={({ onChange: onFormChange, value }) => {
          return (
            <CustomInput
              {...rest}
              checked={value}
              id={name}
              inline
              invalid={Boolean(get(errors, name))}
              onChange={(ev: any) => {
                onFormChange(ev.target.checked);
                onChange && onChange(ev);
                return ev;
              }}
              type="switch"
            />
          );
        }}
      />
      {get(errors, name) && (
        <FormText color="danger">{get(errors, `${name}.message`)}</FormText>
      )}
    </>
  );
};

export default ZSwitchInputController;
