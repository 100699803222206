import React from 'react';
import { ToastContainer, ToastOptions, toast } from 'react-toastify';
import ToastContent, { ToastType } from './ToastContent';

interface ToastProviderValue {
  showToast: (type: ToastType, message: string, options?: ToastOptions) => void;
  dismissToast: () => void;
}

const ToastContext = React.createContext<ToastProviderValue | undefined>(
  undefined
);

const getToast = (type: ToastType) => {
  const toasts = {
    success: () => toast.success,
    error: () => toast.error,
    info: () => toast.info,
    warning: () => toast.warning,
    default: () => toast,
  };

  return (toasts[type] || toasts.default)();
};

export const ToastProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const showToast = (
    type: ToastType,
    message: string,
    options?: ToastOptions
  ) => {
    getToast(type)(<ToastContent message={message} type={type} />, options);
  };

  const dismissToast = () => {
    toast.dismiss();
  };

  const providerValue = React.useMemo(() => ({ showToast, dismissToast }), []);

  return (
    <ToastContext.Provider value={providerValue}>
      {children}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        draggable={false}
        hideProgressBar={true}
      />
    </ToastContext.Provider>
  );
};

export function useToasts(): ToastProviderValue {
  const context = React.useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToasts must be used within a ToastProvider');
  }

  return context;
}
