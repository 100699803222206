import * as React from 'react';

import { ThemeContext } from './ThemeContext';

export const useTheme = () => {
  const context = React.useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeContext');
  }

  return context;
};
