import * as React from 'react';
import { get } from 'lodash-es';
import { AsyncTypeahead } from '@zerintia/powerstone-ui';

import { FilterProps } from './FilterProps.interface';

export const AsyncTypeaheadColumnFilter: React.FC<FilterProps> = ({
  column: { id, setFilter, filterProps },
}) => {
  const {
    labelKey,
    searchFunction,
    placeholder,
    minLength,
    valueKey,
    defaultOptions,
    defaultSelected,
    defaultInputValue,
  } = filterProps;
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState(defaultOptions || []);
  const [isInvalid, setIsInvalid] = React.useState(false);

  const handleSearch = async (query: string) => {
    setIsLoading(true);

    try {
      const resultOptions = await searchFunction(query);

      setOptions(resultOptions);
    } catch (err) {
      console.error(err);
      setIsInvalid(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AsyncTypeahead
      id={id}
      options={options}
      onChange={([value]: any) => {
        setFilter(get(value, valueKey || labelKey));
      }}
      size="sm"
      minLength={minLength || 3}
      filterBy={() => true}
      labelKey={labelKey}
      isLoading={isLoading}
      onSearch={handleSearch}
      placeholder={placeholder}
      isInvalid={isInvalid}
      clearButton
      defaultSelected={defaultSelected}
      defaultInputValue={defaultInputValue}
    />
  );
};
