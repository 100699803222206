import React from 'react';
import { useTranslation } from 'react-i18next';

interface CellDateFormatProps {
  value: string;
  dateFormatOptions?: {
    year?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
    day?: 'numeric' | '2-digit';
    hour?: 'numeric' | '2-digit';
    minute?: 'numeric' | '2-digit';
    second?: 'numeric' | '2-digit';
    fractionalSecondDigits?: 1 | 2 | 3;
  };
}

const CellDateFormat: React.FC<CellDateFormatProps> = ({
  value,
  dateFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
}) => {
  const { i18n } = useTranslation();

  if (value) {
    return (
      <div>
        {new Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(
          new Date(value)
        )}
      </div>
    );
  } else {
    return <div>-</div>;
  }
};

export default CellDateFormat;
