import React from 'react';

import get from 'lodash/get';
import { useFormContext, Controller } from 'react-hook-form';

import { ImageInput, ImageInputProps, FormText } from '@zerintia/powerstone-ui';

interface ZImageInputProps extends ImageInputProps {
  name: string;
  placeholderTitle?: string;
  placeholderBody?: string;
}

const ZImageInputController: React.FC<ZImageInputProps> = ({
  image,
  name,
  onChange,
  ...imageInputProps
}) => {
  const { errors } = useFormContext();
  return (
    <>
      <Controller
        defaultValue={image || null}
        name={name}
        render={({ onChange: onFormChange }: any) => (
          <ImageInput
            {...imageInputProps}
            id={name}
            image={image}
            invalid={Boolean(get(errors, name))}
            onChange={(res: any) => {
              onFormChange(res);
              onChange && onChange(res);
              return res;
            }}
          />
        )}
      />
      {get(errors, name) && (
        <FormText color="danger">{get(errors, `${name}.message`)}</FormText>
      )}
    </>
  );
};

export default ZImageInputController;
