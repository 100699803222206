import * as React from 'react';
import classNames from 'classnames';

import { useTheme } from '../Theme/useTheme';

interface Logos {
  white: string;
  primary: string;
  success: string;
  warning: string;
  danger: string;
  info: string;
  indigo: string;
  purple: string;
  pink: string;
  yellow: string;
}
const logos = {
  white: `${process.env.PUBLIC_URL}/images/logo-white.png`,
  primary: `${process.env.PUBLIC_URL}/images/logo-primary.png`,
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  info: 'info',
  indigo: 'indigo',
  purple: 'purple',
  pink: 'pink',
  yellow: 'yellow',
};

const getLogoUrl = (color: keyof Logos, baseHref: string = '') => {
  return baseHref + logos[color];
};

// Check for background
const getLogoUrlBackground = (
  style: string,
  color: keyof Logos,
  baseHref: string = ''
) => {
  if (style === 'color' || style === 'dark') {
    return baseHref + logos['white'];
  } else {
    return getLogoUrl(color, baseHref);
  }
};

interface LogoThemed {
  checkBackground?: boolean;
  className?: string;
  height?: string | number;
  baseHref?: string;
}

const LogoThemed: React.FC<LogoThemed> = ({
  checkBackground,
  className,
  baseHref,
  ...otherProps
}) => {
  const { style, color } = useTheme();

  return (
    <img
      src={
        checkBackground
          ? getLogoUrlBackground(style, color as keyof Logos, baseHref)
          : getLogoUrl(color as keyof Logos, baseHref)
      }
      className={classNames('d-block', className)}
      alt="Enterprise Logo"
      {...otherProps}
    />
  );
};

export { LogoThemed };
