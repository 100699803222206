import apiConfig from '../services/apiConfig';

export function getApi(managementUri: string) {
  const instance = apiConfig.init(managementUri);

  return {
    regeneratePassword: (userId: string, payload: Record<string, any>) => {
      return instance.put(
        `${managementUri}/zf/api/users/${userId}/regenerate_password`,
        payload
      );
    },
    regeneratePin: (userId: string, payload: Record<string, any>) => {
      return instance.put(
        `${managementUri}/zf/api/users/${userId}/regenerate_pin`,
        payload
      );
    },
    getUserApiKey: (userId: string) => {
      return instance.get(`${managementUri}/zf/api/users/${userId}/apikey`);
    },
    regenerateApikey: (userId: string) => {
      return instance.put(
        `${managementUri}/zf/api/users/${userId}/regenerate_apikey`
      );
    },
    updateActiveSites: (profileId: string, payload: Record<string, any>) => {
      return instance.put(
        `${managementUri}/zf/api/profiles/${profileId}/active-sites`,
        payload
      );
    },
    logOut: () => {
      return instance.post(
        `${managementUri}/zf/api/auth/logout`
      );
    },
  };
}
