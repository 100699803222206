import * as React from 'react';

function ManagementLogo() {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <svg id="database" viewBox="0 0 448 512">
          <path
            fill="#F9FAFC"
            d="M448 73.143v45.714C448 159.143 347.667 192 224 192S0 159.143 0 118.857V73.143C0 32.857 100.333 0 224 0s224 32.857 224 73.143zM448 176v102.857C448 319.143 347.667 352 224 352S0 319.143 0 278.857V176c48.125 33.143 136.208 48.572 224 48.572S399.874 209.143 448 176zm0 160v102.857C448 479.143 347.667 512 224 512S0 479.143 0 438.857V336c48.125 33.143 136.208 48.572 224 48.572S399.874 369.143 448 336z"
          ></path>
        </svg>
      </defs>
      <g clipPath="url(#clip0)">
        <rect x="-35" y="-40" width="120" height="120" fill="#98a4b2" />
        <g strokeWidth="1" fill="#b4c3d6" stroke="#b4c3d6">
          <path d="M-2.47706 25.192C-2.41843 25.1773 -2.27263 25.1676 -2.00548 25.1995C-1.75127 25.2297 -1.4296 25.2919 -1.04484 25.386C-0.276146 25.5739 0.707579 25.8798 1.83965 26.2758C4.10166 27.067 6.92024 28.2054 9.72527 29.4396C12.5301 30.6737 15.3121 31.9995 17.5012 33.164C18.5965 33.7466 19.5368 34.2853 20.255 34.7492C20.9952 35.2273 21.4372 35.5876 21.6017 35.8069C21.6398 35.8578 21.6987 35.987 21.7353 36.2483C21.7705 36.4996 21.7791 36.8266 21.7578 37.2276C21.7152 38.029 21.5568 39.0672 21.3059 40.2705C20.8049 42.6734 19.9485 45.6727 18.9767 48.6256C18.0051 51.5775 16.9229 54.4689 15.9742 56.6547C15.4992 57.7492 15.0622 58.6561 14.6932 59.3024C14.5081 59.6266 14.3472 59.8727 14.2139 60.0407C14.1473 60.1246 14.0947 60.1802 14.0563 60.2141C14.0428 60.2261 14.0332 60.2334 14.0275 60.2375C13.9561 60.2577 13.782 60.2907 13.478 60.3289C13.1645 60.3683 12.7501 60.4093 12.2466 60.45C11.2402 60.5313 9.89244 60.6107 8.31225 60.6761C5.15224 60.807 1.07473 60.8818 -3.03924 60.807C-7.15623 60.7322 -11.2954 60.5077 -14.5847 60.0431C-16.2307 59.8106 -17.6468 59.5203 -18.7346 59.1652C-19.8507 58.8009 -20.5172 58.4003 -20.7915 58.0163C-20.9038 57.859 -20.9902 57.6053 -21.0109 57.2174C-21.0314 56.8326 -20.9853 56.3528 -20.8689 55.7818C-20.6362 54.6395 -20.1379 53.2034 -19.4357 51.5665C-18.0332 48.2972 -15.8528 44.3073 -13.4956 40.416C-11.1399 36.5271 -8.61671 32.752 -6.53573 29.9152C-5.49455 28.4958 -4.56907 27.3182 -3.83384 26.4805C-3.46552 26.0609 -3.15213 25.7349 -2.9009 25.5084C-2.77519 25.3951 -2.67204 25.3131 -2.59095 25.2582C-2.52094 25.2107 -2.48478 25.1955 -2.47733 25.1924C-2.47547 25.1916 -2.4754 25.1916 -2.47706 25.192Z" />
          <path d="M31.6567 37.2039C32.9277 37.0627 35.1142 37.2743 37.8127 37.7615C40.4933 38.2455 43.6269 38.9913 46.7641 39.8877C49.9015 40.7841 53.0344 41.8287 55.7147 42.9082C58.4066 43.9925 60.6023 45.0965 61.8945 46.1016C64.5716 48.1838 67.8265 51.2958 69.7355 54.1593C70.6961 55.6002 71.2711 56.913 71.3208 57.9649C71.3451 58.4786 71.2436 58.9146 71.0145 59.2802C70.785 59.6465 70.4026 59.9815 69.7984 60.2501C69.2043 60.5141 68.1235 60.7251 66.6168 60.8714C65.1286 61.016 63.2811 61.093 61.1928 61.1164C57.0173 61.1631 51.9105 60.9949 46.8524 60.7326C36.725 60.2075 26.8519 59.3085 25.0837 59.0138C24.9232 58.987 24.694 58.8285 24.5073 58.2306C24.3276 57.655 24.2408 56.8207 24.2546 55.7823C24.282 53.7156 24.7046 50.9819 25.4108 48.2313C26.1169 45.4811 27.0979 42.7482 28.2251 40.6772C28.7891 39.6409 29.3794 38.7895 29.9757 38.1856C30.5761 37.5775 31.1416 37.2611 31.6567 37.2039Z" />
          <path d="M27.2046 -22.2335C28.1219 -21.8405 29.1051 -20.8505 30.0791 -19.4869C31.0409 -18.1404 31.9458 -16.499 32.7273 -14.8987C33.5077 -13.3008 34.1589 -11.7568 34.6152 -10.6112C34.8432 -10.0388 35.0223 -9.56676 35.1441 -9.23834C35.2048 -9.07482 35.2513 -8.94696 35.2825 -8.86009C35.2681 -8.79459 35.2487 -8.7065 35.2244 -8.59708C35.166 -8.33416 35.0795 -7.94811 34.9672 -7.45636C34.7427 -6.47282 34.4151 -5.06663 34.0033 -3.37715C33.1795 0.0025177 32.0191 4.51259 30.6723 9.03933C29.3243 13.5702 27.7946 18.1002 26.234 21.5279C25.4528 23.2438 24.6736 24.6613 23.9183 25.6605C23.1422 26.6872 22.4771 27.1569 21.9518 27.2094C20.5294 27.3516 18.6021 27.1392 16.4661 26.6656C14.3387 26.1939 12.0392 25.471 9.88593 24.6171C7.73094 23.7625 5.73725 22.7826 4.21626 21.8022C3.45549 21.3119 2.82373 20.8282 2.35133 20.3679C1.87297 19.9018 1.5897 19.4901 1.47593 19.1488C1.44781 19.0644 1.43708 18.8164 1.54285 18.328C1.64229 17.8688 1.82535 17.278 2.08805 16.5684C2.61267 15.1513 3.43624 13.3102 4.47828 11.1934C6.56101 6.96253 9.49729 1.66928 12.6042 -3.45899C15.712 -8.58878 18.9822 -13.5395 21.7313 -17.0901C23.1081 -18.8683 24.3404 -20.2771 25.3463 -21.1809C25.8511 -21.6345 26.2775 -21.9413 26.6194 -22.1084C26.9746 -22.2821 27.1429 -22.26 27.2046 -22.2335Z" />
          <path d="M38.6933 -0.452427C38.7613 -0.532597 38.8067 -0.57006 38.8298 -0.586622C38.8386 -0.583989 38.8494 -0.580284 38.8626 -0.575075C38.9092 -0.556554 38.9722 -0.523642 39.0525 -0.469561C39.2145 -0.360397 39.4134 -0.187578 39.647 0.0538483C40.1132 0.53556 40.668 1.23829 41.2814 2.10254C42.5055 3.82746 43.9214 6.13681 45.2692 8.45799C46.6155 10.7766 47.8877 13.0965 48.8237 14.8374C49.2916 15.7077 49.6752 16.4328 49.9418 16.9402C50.075 17.1939 50.179 17.3932 50.2496 17.5289L50.33 17.6836L50.3505 17.7231L50.3556 17.733L50.3561 17.7339L50.3612 17.7443L50.3817 17.7853L50.4617 17.9457C50.5317 18.0864 50.6344 18.2929 50.7649 18.5563C51.0259 19.083 51.3982 19.837 51.8432 20.7456C52.7331 22.5629 53.9131 24.9978 55.074 27.4695C56.2357 29.9426 57.3755 32.4467 58.1866 34.4045C58.5927 35.3849 58.9123 36.2184 59.111 36.8384C59.2054 37.1329 59.2685 37.367 59.3019 37.5397C59.1077 37.5451 58.8304 37.5303 58.4694 37.4918C57.6794 37.4075 56.5677 37.219 55.2321 36.9481C52.5653 36.4073 49.0521 35.5479 45.5348 34.575C42.0168 33.6019 38.5068 32.5187 35.8441 31.5318C34.5107 31.0376 33.4037 30.5727 32.618 30.1628C32.2237 29.9572 31.9265 29.7737 31.7256 29.6163C31.5554 29.483 31.5024 29.405 31.4894 29.3859C31.4852 29.3798 31.4852 29.3797 31.4866 29.3857C31.4677 29.31 31.4521 29.1214 31.4636 28.7849C31.4746 28.4642 31.508 28.0521 31.563 27.5583C31.6727 26.5716 31.8654 25.2841 32.1224 23.7984C32.6361 20.8283 33.4028 17.0881 34.2634 13.4212C35.1245 9.75236 36.0768 6.1689 36.9606 3.50829C37.4034 2.17504 37.8232 1.09172 38.199 0.349087C38.3881 -0.0246099 38.5546 -0.288849 38.6933 -0.452427ZM59.3279 37.7654C59.3278 37.7654 59.3279 37.7636 59.3283 37.7603C59.3282 37.7638 59.3279 37.7654 59.3279 37.7654ZM59.5189 37.5179C59.5281 37.5141 59.5299 37.5145 59.5208 37.5173C59.5202 37.5175 59.5196 37.5177 59.5189 37.5179Z" />
        </g>
      </g>
      <use xlinkHref="#database" width="28" height="28" x="11" y="12"></use>
      <defs>
        <clipPath id="clip0">
          <rect width="50" height="50" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ManagementLogo;
