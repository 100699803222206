import React from 'react';
import { Media } from 'reactstrap';

export type ToastType = 'success' | 'error' | 'warning' | 'info';

interface ToastContentProps {
  message: string;
  type: ToastType;
}

const ToastContent: React.FC<ToastContentProps> = ({ message, type }) => (
  <Media>
    <Media body>
      {type === 'error' && (
        <Media heading tag="h6">
          Error
        </Media>
      )}
      {message.split('\n').length === 1 && (
        <span className="text-body">{message}</span>
      )}
      {message.split('\n').length > 1 && (
        <p>
          {message.split('\n').map((text, index) => {
            return (
              <p style={{ margin: '0' }} key={`text-${index}-${text}`}>
                {text}
              </p>
            );
          })}
        </p>
      )}
    </Media>
  </Media>
);

export default ToastContent;
