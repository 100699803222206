import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import enCoreTranslations from './locales/en/core';
import esCoreTranslations from './locales/es/core';

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: window?.localStorage?.getItem('i18nextLng') || window?.navigator?.language?.substring(0, 2) || 'en',
    fallbackLng: 'en',
    ns: ['core', 'translation'],
    defaultNS: 'translation',
    fallbackNS: 'translation',
    keySeparator: false,
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

i18n.addResources('en', 'core', enCoreTranslations);
i18n.addResources('es', 'core', esCoreTranslations);

export default i18n;
