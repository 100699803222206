import React from 'react';
import classNames from 'classnames';

import Utils from '../utils';

interface SidebarContentProps {
  slim?: boolean;
  collapsed?: boolean;
  animationsDisabled?: boolean;
  pageConfig?: Record<string, any>;
  children?: React.ReactNode;
}

interface SidebarContentState {
  entryAnimationFinished: boolean;
}

export class SidebarContent extends React.Component<
  SidebarContentProps,
  SidebarContentState
> {
  private _isMounted = false;
  public sidebarEntryAnimate: any;
  public slimSidebarAnimate: any;
  public slimMenuAnimate: any;

  sidebarRef = React.createRef() as any;

  constructor(props: SidebarContentProps) {
    super(props);

    this.state = {
      entryAnimationFinished: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.sidebarEntryAnimate = new Utils.SidebarEntryAnimate();
    this.slimSidebarAnimate = new Utils.SlimSidebarAnimate();
    this.slimMenuAnimate = new Utils.SlimMenuAnimate();

    this.sidebarEntryAnimate.assignParentElement(this.sidebarRef.current);
    this.slimSidebarAnimate.assignParentElement(this.sidebarRef.current);
    this.slimMenuAnimate.assignSidebarElement(this.sidebarRef.current);

    this.sidebarEntryAnimate.executeAnimation().then(() => {
      if (this._isMounted) {
        this.setState({ entryAnimationFinished: true });
      }
    });
  }

  componentWillUnmount() {
    this.sidebarEntryAnimate.destroy();
    this.slimSidebarAnimate.destroy();
    this.slimMenuAnimate.destroy();

    this._isMounted = false;
  }

  render() {
    const {
      animationsDisabled,
      collapsed,
      pageConfig = {},
      slim,
      children,
    } = this.props;

    const sidebarClass = classNames('sidebar', 'sidebar--animations-enabled', {
      'sidebar--slim': slim || pageConfig.sidebarSlim,
      'sidebar--collapsed': collapsed || pageConfig.sidebarCollapsed,
      'sidebar--animations-disabled':
        animationsDisabled || pageConfig.animationsDisabled,
      'sidebar--animate-entry-complete': this.state.entryAnimationFinished,
    });

    return (
      <div className={sidebarClass} ref={this.sidebarRef}>
        {children}
      </div>
    );
  }
}
