import * as React from 'react';

import uniqBy from 'lodash/uniqBy';
import remove from 'lodash/remove';

const actionTypes = {
  SET_USER: 'setUser',
  REMOVE_USER: 'removeUser',
  GET_SITES: 'getSites',
  ADD_SITES: 'addSite',
  UPDATE_SITE: 'updateSite',
  SELECT_ALL_SITES: 'selectAllSites',
  SELECT_ONE_SITE: 'selectOneSite',
  REMOVE_SITE_BY_ID: 'removeSiteById',
  REMOVE_SITES: 'removeSites',
  ACTIVE_SESSION: 'activeSession',
  ADD_RESOURCES: 'addResources',
  REMOVE_RESOURCES: 'removeResources',
  DEACTIVATE_SESSION: 'deactivateSession',
  ADD_PRIVILEGES: 'addPrivileges',
  REMOVE_PRIVILEGES: 'removePrivileges',
  UPDATE: 'update',
  ACTIVE_CHANGE_PASSWORD: 'activeChangePassword',
  DEACTIVE_CHANGE_PASSWORD: 'deactiveChangePassword'
};
type sites = {
  _id: string;
  name: string;
  selected: boolean;
};
type User = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  job_titles?: any[];
  profiles: any[];
};
type Action = {
  type:
    | 'setUser'
    | 'removeUser'
    | 'getSites'
    | 'addSite'
    | 'updateSite'
    | 'selectAllSites'
    | 'selectOneSite'
    | 'removeSiteById'
    | 'removeSites'
    | 'activeSession'
    | 'addResources'
    | 'removeResources'
    | 'addPrivileges'
    | 'removePrivileges'
    | 'update'
    | 'activeChangePassword'
    | 'deactiveChangePassword';
  payload?: any;
};
type UserSessionState = {
  managementUri: string;
  user?: User;
  profileId?: string;
  sites?: sites[];
  isActiveSession: boolean;
  resources?: Record<string, any>;
  privileges?: Record<string, ActionType>;
  changePassword?: boolean;
};

type DispatchFn = (action: Action) => void;

interface ActionType {
  read: boolean;
  update: boolean;
  create: boolean;
  delete: boolean;
  menu: boolean;
  manage: boolean;
  view: boolean;
  execute: boolean;
  initiate: boolean;
  pause: boolean;
  receive: boolean;
  assign: boolean;
  configure: boolean;
  record: boolean;
  point: boolean;
  workload: boolean;
  planning: boolean;
  multiple: boolean;
  signDocument: boolean;
  sync: boolean;
  addUser: boolean;
  changeMainVideo: boolean;
  chat: boolean;
  controlMainAudio: boolean;
  draw: boolean;
  flashlight: boolean;
  freeText: boolean;
  freeze: boolean;
  gridView: boolean;
  muteMainVideo: boolean;
  remoteCameraControl: boolean;
  remoteCapture: boolean;
  resolution: boolean;
  screenshot: boolean;
  shareScreen: boolean;
  slaveCamera: boolean;
  speechToText: boolean;
  zoom: boolean;
  sessionStats: boolean;
  print: boolean;
  scan: boolean;
  muteOwnAudio: boolean;
  muteOwnVideo: boolean;
  fullscreen: boolean;
  lowBandwidth: boolean;
}

const UserSessionStateContext =
  React.createContext<UserSessionState | undefined>(undefined);
const UserSessionUpdaterContext =
  React.createContext<DispatchFn | undefined>(undefined);

function userSessionreducer(state: UserSessionState, action: Action) {
  switch (action.type) {
    case actionTypes.SET_USER: {
      const updateUser = {
        ...state,
        user: action.payload,
      };

      return updateUser;
    }
    case actionTypes.REMOVE_USER: {
      const deleteUser = {
        ...state,
        user: undefined,
      };

      return deleteUser;
    }
    case actionTypes.ADD_SITES: {
      const selectableSites = action.payload.map(({ _id, name }: any) => ({
        _id,
        name,
        selected: true,
      }));
      const updatedSites = uniqBy(
        [...(state.sites || []), ...selectableSites],
        '_id'
      );
      const userSessionUpdated = {
        ...state,
        sites: updatedSites,
      };

      return userSessionUpdated;
    }
    case actionTypes.ADD_RESOURCES: {
      const userSessionUpdated = {
        ...state,
        resources: action.payload,
      };
      return userSessionUpdated;
    }
    case actionTypes.REMOVE_RESOURCES: {
      return {
        ...state,
        resources: undefined,
      };
    }
    case actionTypes.UPDATE_SITE: {
      const updatedSites = state.sites?.map((site: any) => {
        return site._id === action.payload._id
          ? {
              ...site,
              name: action.payload.name || site.name,
              selected:
                typeof action.payload.selected == 'boolean'
                  ? action.payload.selected
                  : site.selected,
            }
          : site;
      });
      return {
        ...state,
        sites: updatedSites,
      };
    }
    case actionTypes.SELECT_ALL_SITES: {
       const updatedSites = state.sites?.map((site: any) => {
         return {...site, selected: true }
       });
       return {
         ...state,
         sites: updatedSites
       }
    }

    case actionTypes.SELECT_ONE_SITE: {
      const updatedSites = state.sites?.map((site: any) => {
        return {...site, selected: site._id === action.payload._id }
      });
      return {
        ...state,
        sites: updatedSites
      }
   }

    case actionTypes.REMOVE_SITE_BY_ID: {
      remove(state.sites || [], (site: any) => site._id === action.payload);
      const userSessionUpdated = {
        ...state,
        sites: [...(state.sites || [])],
      };
      return userSessionUpdated;
    }
    case actionTypes.REMOVE_SITES: {
      return {
        ...state,
        sites: undefined,
      };
    }
    case actionTypes.ACTIVE_SESSION: {
      return {
        ...state,
        isActiveSession: true,
      };
    }
    case actionTypes.DEACTIVATE_SESSION: {
      return {
        ...state,
        isActiveSession: false,
      };
    }
    case actionTypes.ADD_PRIVILEGES: {
      return {
        ...state,
        privileges: action.payload,
      };
    }
    case actionTypes.REMOVE_PRIVILEGES: {
      return {
        ...state,
        privileges: undefined,
      };
    }
    case actionTypes.ACTIVE_CHANGE_PASSWORD: {
      return {
        ...state,
        changePassword: true,
      };
    }
    case actionTypes.DEACTIVE_CHANGE_PASSWORD: {
      return {
        ...state,
        changePassword: false,
      };
    }
    case actionTypes.UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

interface UserSessionProviderProps {
  managementUri: string;
  user?: User;
  profileId?: string;
  resources?: Record<string, any>[];
  sites?: sites[];
  privileges?: Record<string, ActionType>;
  changePassword?: boolean;
  children?: React.ReactNode;
 }

const UserSessionProvider: React.FC<UserSessionProviderProps> = ({
  managementUri,
  user,
  profileId,
  sites = [],
  resources = [],
  privileges,
  children,
  changePassword,
}) => {
  const [state, dispatch] = React.useReducer(userSessionreducer, {
    managementUri,
    user,
    profileId,
    sites,
    isActiveSession: false,
    resources,
    privileges,
    changePassword
  });

  return (
    <UserSessionStateContext.Provider value={state}>
      <UserSessionUpdaterContext.Provider value={dispatch}>
        {children}
      </UserSessionUpdaterContext.Provider>
    </UserSessionStateContext.Provider>
  );
};

function useUserSessionState(): UserSessionState {
  const userSessionState = React.useContext(UserSessionStateContext);
  if (userSessionState === undefined) {
    throw new Error(
      'useUserSessionState must be used within a UserSessionProvider'
    );
  }
  return userSessionState;
}

function useUserSessionUpdater(): DispatchFn {
  const dispatch = React.useContext(UserSessionUpdaterContext);

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useUserSessionUpdater must be used within a UserSessionProvider'
    );
  }

  return dispatch;
}

function useUserSession(): [UserSessionState, DispatchFn] {
  return [useUserSessionState(), useUserSessionUpdater()];
}

export {
  UserSessionProvider,
  useUserSessionState,
  useUserSessionUpdater,
  useUserSession,
};
