import * as React from 'react';
import { Col, ColProps, FormGroup, Label, LabelProps } from '@zerintia/powerstone-ui';

interface ZFormItemProps {
  label: string | JSX.Element;
  htmlFor: string;
  row?: boolean;
  labelSize?: number;
  inputSize?: number;
  inputContainerProps?: ColProps;
  labelProps?: LabelProps;
  required?: boolean;
  children?: React.ReactNode;
}

const ZFormItem: React.FC<ZFormItemProps> = ({
  label,
  htmlFor,
  row = true,
  labelSize = row ? 4 : 12,
  inputSize = row ? 8 : 12,
  inputContainerProps,
  labelProps,
  children,
  required,
}) => {
  return (
    <FormGroup row={row}>
      <Label htmlFor={htmlFor} sm={labelSize} {...labelProps}>
        {label}{required && <>&nbsp;*</>}
      </Label>
      <Col sm={inputSize} {...inputContainerProps}>{children}</Col>
    </FormGroup>
  );
};

export default ZFormItem;
