import * as React from 'react';
import {
  FormProvider,
  UseFormMethods,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form';
import { Form } from '@zerintia/powerstone-ui';

type ZFormProps = {
  id?: string;
  onSubmit?: SubmitHandler<Record<string, any>>;
  onError?: SubmitErrorHandler<Record<string, any>>;
  form: UseFormMethods;
  children: React.ReactNode;
};

const ZForm: React.FC<ZFormProps> = ({
  children,
  id,
  onSubmit,
  onError,
  form,
}) => {
  return (
    <FormProvider {...form}>
      <Form
        id={id}
        aria-label="form"
        noValidate
        onSubmit={form.handleSubmit(onSubmit || (() => {}), onError)}
      >
        {children}
      </Form>
    </FormProvider>
  );
};

export default ZForm;
