import * as React from 'react';
import { Col, Row, Button } from 'reactstrap';

interface ZModalDialogProps {
  text: string;
  acceptLabel?: string | JSX.Element;
  cancelLabel?: string | JSX.Element;
  onAccept: () => void;
  onCancel: () => void;
}

const ZModalDialog: React.FC<ZModalDialogProps> = ({
  text,
  acceptLabel = 'Accept',
  cancelLabel = 'Cancel',
  onAccept,
  onCancel,
}) => {
  return (
    <>
      <Col>
        <Row className="text-center">
          <p>{text}</p>
        </Row>
        <Row>
          <section className="ml-auto">
            <Button
              className="mr-2"
              color="primary"
              type="button"
              onClick={onAccept}
            >
              {acceptLabel}
            </Button>
            <Button color="secondary" type="button" onClick={onCancel}>
              {cancelLabel}
            </Button>
          </section>
        </Row>
      </Col>
    </>
  );
};

export default ZModalDialog;
