import { useMemo } from 'react';
import { useUserSessionState } from '../contexts/session';

interface PlainSites {
  _id: string;
  name: string;
}

export function useSelectedSites(): PlainSites[] | undefined {
  const { sites } = useUserSessionState();

  const result = useMemo(
    () =>
      sites
        ?.filter(({ selected }) => selected)
        .map(({ selected, ...rest }) => rest),
    [sites]
  );

  return result;
}
