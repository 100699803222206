import * as React from 'react';
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useAsyncDebounce,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  useGroupBy,
  useExpanded,
} from 'react-table';
import { Table as RSTable, PageLoader } from '@zerintia/powerstone-ui';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';
import TablePagination from './components/TablePagination';
import { DropDownActions } from './components/DropDownActions';
import {
  handleZerintiaColumns,
  ZerintiaColumn,
  ZerintiaActionsColumn,
  ApiParams,
  ResourcesProps,
} from './utils';
import TableNabvar from './components/TableNavbar';
import TableColumnSelector from './components/ColumnSelector';
import IndeterminateCheckbox from './components/IntermediateCheckbox';

import style from './style.module.css';
import TableSwitchShowDeleted from './components/SwitchShowDeleted';

interface ServerSideTableProps {
  data: any[];
  columnsDef: (ZerintiaColumn & Record<string, any>)[];
  actionsColumn?: ZerintiaActionsColumn[];
  resources?: ResourcesProps[];
  onTableChange: (params: ApiParams) => void;
  count: number;
  delay?: number;
  isLoading: boolean;
  defaultParams?: ApiParams;
  resizableColumns?: boolean;
  selectableColumns?: boolean;
  rowSelection?: boolean;
  canGroup?: boolean;
  onRowsSelected?: (rows: any[]) => void;
  responsive?: boolean;
  rowProps?: any;
  containerClassName?: any;
  showDeleted?: boolean;
}

const ServerSideTable: React.FC<ServerSideTableProps> = ({
  data,
  columnsDef,
  actionsColumn,
  defaultParams,
  onTableChange,
  count,
  delay = 200,
  resizableColumns = false,
  selectableColumns = false,
  rowSelection = false,
  canGroup = false,
  onRowsSelected = () => {},
  responsive = false,
  isLoading = false,
  rowProps,
  containerClassName = '',
  showDeleted = false,
}) => {
  const [actionWidth, setActionWidth] = React.useState(-1);
  const [currentPageCount, setCurrentPageCount] = React.useState(0);

  const { reactTableColumns, handleTableChange } = React.useMemo(
    () => handleZerintiaColumns(columnsDef, defaultParams),
    [columnsDef, defaultParams]
  );
  const columns = React.useMemo(() => reactTableColumns, [columnsDef]);
  const onFetchDataDebounced = useAsyncDebounce(
    (tableState, defaultParams, showDeletedParams = undefined) => {
      const params = handleTableChange(tableState);
      if (showDeletedParams) {
        params.filters.push(showDeletedParams.filter);
        params.values.push(showDeletedParams.value);
      }
      onTableChange({ ...defaultParams, ...params });
    },
    delay
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    selectedFlatRows,
    // @ts-ignore
    state: { filters, sortBy, pageSize, pageIndex, selectedRowIds },
    allColumns,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: currentPageCount,
      autoResetPage: false,
      initialState: {
        // @ts-ignore
        pageSize: defaultParams?.limit || 10,
        pageIndex: defaultParams?.offset || 0,
      },
      disableGroupBy: !canGroup,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    (hooks) => {
      rowSelection &&
        hooks.visibleColumns.push((columns: any[]) =>
          [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }: any) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }: any) => (
                <div>
                  <IndeterminateCheckbox
                    row={row}
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
          ].concat(columns)
        );
    }
  );
  const [showDeletedValue, setShowDeletedValue] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    onFetchDataDebounced(
      { filters, sortBy, pageIndex, pageSize },
      defaultParams,
      showDeleted
        ? { filter: 'deleted', value: `*${showDeletedValue}` }
        : undefined
    );
    setCurrentPageCount(Math.ceil(count / pageSize));
  }, [
    useAsyncDebounce,
    filters,
    sortBy,
    pageIndex,
    pageSize,
    defaultParams,
    showDeletedValue,
  ]);

  React.useEffect(() => {
    setCurrentPageCount(Math.ceil(count / pageSize));
  }, [count]);

  React.useEffect(() => {
    onRowsSelected(selectedFlatRows.map((d: any) => d.original));
  }, [selectedRowIds]);

  React.useLayoutEffect(() => {
    if (!isLoading) {
      const localActionWidth = Math.max(
        ...Array.from(document.getElementsByClassName('zDropDownActions')).map(
          (item: any) => item.offsetWidth
        )
      );
      if (localActionWidth > 0) {
        setActionWidth(localActionWidth);
      }
    }
  }, [isLoading]);

  return (
    <div>
      {isLoading && <PageLoader />}
      {(selectableColumns || showDeleted) && (
        <TableNabvar>
          {selectableColumns && <TableColumnSelector allColumns={allColumns} />}
          {showDeleted && (
            <TableSwitchShowDeleted
              setShow={setShowDeletedValue}
              show={showDeletedValue}
            />
          )}
        </TableNabvar>
      )}
      <div
        className={`${style.table} ${style.tablescroll} ${containerClassName}`}
      >
        <RSTable
          {...getTableProps()}
          hover
          responsive={responsive}
          className="text-inverse"
        >
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps()} className="font-weight-bold">
                    {column.canGroupBy ? (
                      <span {...column.getGroupByToggleProps()}>
                        {column.isGrouped ? (
                          <small>
                            <FontAwesomeIcon
                              icon={'layer-group'}
                              className="text-primary mr-1"
                            />
                          </small>
                        ) : (
                          <small>
                            <FontAwesomeIcon
                              icon={'layer-group'}
                              className="text-muted mr-1"
                            />
                          </small>
                        )}
                      </span>
                    ) : null}
                    <span
                      {...column.getSortByToggleProps({
                        title: undefined,
                      })}
                    >
                      {column.render('Header')}

                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon
                            className="ml-1 text-muted"
                            icon={'caret-down'}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="ml-1 text-muted"
                            icon={'caret-up'}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                    {resizableColumns && (
                      <div
                        {...column.getResizerProps()}
                        className={`${style.resizer} ${
                          column.isResizing ? style.isResizing : ''
                        }`}
                      />
                    )}
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
                {actionsColumn?.length && (
                  <th
                    style={
                      actionWidth > 0
                        ? {
                            boxSizing: 'content-box',
                            maxWidth: actionWidth,
                            minWidth: actionWidth,
                            width: actionWidth,
                          }
                        : {}
                    }
                  >
                    <span></span>
                  </th>
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, index: number) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps(
                    typeof rowProps === 'function' ? rowProps(row) : rowProps
                  )}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.isGrouped ? (
                          <>
                            <small {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? (
                                <FontAwesomeIcon icon={'chevron-down'} />
                              ) : (
                                <FontAwesomeIcon icon={'chevron-right'} />
                              )}
                            </small>{' '}
                            {cell.render('Cell', { editable: false })} (
                            {row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : (
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                  {actionsColumn?.length && (
                    <td
                      style={
                        actionWidth > 0
                          ? {
                              boxSizing: 'content-box',
                              maxWidth: actionWidth,
                              minWidth: actionWidth,
                              width: actionWidth,
                            }
                          : {}
                      }
                    >
                      <DropDownActions
                        actions={actionsColumn}
                        row={row}
                        rowIndex={index}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </RSTable>
      </div>
      <TablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageSize={pageSize}
      />
    </div>
  );
};

export default ServerSideTable;
