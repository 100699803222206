import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { FormText, Select, SelectProps } from '@zerintia/powerstone-ui';

interface ZSelectProps extends SelectProps {
  allowEmpty?: boolean;
  labelKey?: string;
  name: string;
  onChange?: Function;
  valueKey?: string;
}

const ZSelect: React.FC<ZSelectProps> = ({
  allowEmpty,
  labelKey,
  name,
  onChange,
  valueKey,
  defaultValue,
  ...rest
}) => {
  const { errors } = useFormContext();

  return (
    <>
      <Controller
        defaultValue={defaultValue}
        name={name}
        render={({ onChange: onFormChange }: any) => (
          <Select
            {...rest}
            name={name}
            allowEmpty={allowEmpty}
            labelKey={labelKey}
            valueKey={valueKey}
            invalid={Boolean(get(errors, name))}
            onChange={(res: any) => {
              onFormChange(res);
              onChange && onChange(res);
              return res;
            }}
          />
        )}
      />
      {get(errors, name) && (
        <FormText color="danger">{get(errors, `${name}.message`)}</FormText>
      )}
    </>
  );
};

export default ZSelect;
