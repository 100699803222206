import React from 'react';

import get from 'lodash/get';
import { Input, InputProps } from 'reactstrap';

export interface SelectProps extends InputProps {
  name: string;
  allowEmpty?: boolean;
  labelKey?: string;
  valueKey?: string;
  invalid?: boolean;
  placeholder?: any;
  options?: any;
  onChange?: any;
}

export const Select: React.FC<SelectProps> = ({
  name,
  allowEmpty,
  labelKey,
  valueKey,
  invalid,
  placeholder,
  options,
  onChange,
  ...rest
}) => {
  return (
    <Input
      {...rest}
      data-testid="select-multiple"
      id={name}
      type="select"
      name={name}
      onChange={onChange}
      invalid={invalid}
    >
      <option value="" disabled={!allowEmpty}>
        {placeholder}
      </option>
      {options?.map((option: any) => {
        return (
          <option
            data-testid={get(option, labelKey || 'name')}
            key={option[valueKey || 'value'] || option}
            value={option[valueKey || 'value'] || option}
          >
            {get(option, labelKey || 'name') || JSON.stringify(option)}
          </option>
        );
      })}
    </Input>
  );
};
