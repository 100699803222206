import React, { useState } from 'react';
import moment, { Moment } from 'moment';

import {
  DayOfWeekShape,
  OrientationShape,
  SingleDatePicker,
} from 'react-dates';

import 'react-dates/initialize';

export interface DatePickerProps {
  appendToBody?: boolean;
  defaultValue?: Date;
  firstDayOfWeek?: DayOfWeekShape | null | undefined;
  id: string;
  isOutsideRange?: (day: any) => boolean;
  onChange?: (startDate: Date | null) => void;
  orientation?: OrientationShape;
  placeholder?: string;
  showClearDate?: boolean;
  small?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  id,
  defaultValue,
  onChange = () => {},
  placeholder,
  ...props
}) => {
  const [date, setDate] = useState<Moment | null>(
    defaultValue ? moment(defaultValue) : null
  );
  const [focusedInput, setFocusedInput] = useState<boolean>(false);

  const handlendDatesChange = (arg: Moment | null) => {
    onChange(arg?.toDate() || null);
    setDate(arg);
  };
  const handleFocusChange = ({ focused }: any) => {
    setFocusedInput(focused);
  };

  return (
    <SingleDatePicker
      {...props}
      id={id}
      date={date}
      focused={focusedInput}
      placeholder={placeholder}
      onDateChange={handlendDatesChange}
      onFocusChange={handleFocusChange}
    />
  );
};
