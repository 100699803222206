import React from 'react';

import { FontAwesomeIcon } from '@zerintia/powerstone-icons';
import { SidebarMenu, SidebarMenuItem } from '../SidebarMenu';
import { useTranslation } from 'react-i18next';

interface SidebarMiddleNavProps {
  resources: Resource[];
}

export const SidebarMiddleNav: React.FC<SidebarMiddleNavProps> = ({
  resources,
}) => {
  const { t } = useTranslation();

  const buildSideBarMenu = (resources: Resource[]) => {
    return resources.map((item: Resource, index) => {
      if (item.href) {
        return (
          <SidebarMenuItem
            key={`${item.title}-${index}`}
            icon={
              item?.icon ? (
                <i>
                  <FontAwesomeIcon icon={item.icon as any} />
                </i>
              ) : (
                <></>
              )
            }
            title={t(item.title)}
            href={item.href}
            onClick={item.onClick}
          />
        );
      } else if (item.children) {
        return (
          <SidebarMenuItem
            key={`${item.title}-${index}`}
            icon={
              item?.icon ? (
                <i>
                  <FontAwesomeIcon icon={item.icon as any} />
                </i>
              ) : (
                <></>
              )
            }
            title={t(item.title)}
          >
            {buildSideBarMenu(item.children)}
          </SidebarMenuItem>
        );
      } else {
        return (
          <SidebarMenuItem
            key={`${item.title}-${index}`}
            icon={
              item?.icon ? (
                <i>
                  <FontAwesomeIcon icon={item?.icon as any} />
                </i>
              ) : (
                <></>
              )
            }
            title={t(item.title)}
            to={item.to}
          />
        );
      }
    });
  };

  const sites = buildSideBarMenu(resources);
  const filteredSites = sites.filter((item) => typeof item !== 'undefined');

  return filteredSites.length ? (
    <SidebarMenu>{filteredSites}</SidebarMenu>
  ) : null;
};
