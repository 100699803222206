import * as React from 'react';
import classNames from 'classnames';

import { usePageConfig } from '../Layout';

interface EmptyLayoutProps {
  className?: string;
  children?: React.ReactNode;
}

const EmptyLayout: React.FC<EmptyLayoutProps> = ({ children, className }) => {
  const { setElementsVisibility } = usePageConfig();
  const emptyLayoutClass = classNames('fullscreen', className);

  React.useEffect(() => {
    setElementsVisibility({
      navbarHidden: true,
      sidebarHidden: true,
      footerHidden: true,
    });

    return () => {
      setElementsVisibility({
        navbarHidden: false,
        sidebarHidden: false,
        footerHidden: false,
      });
    };
  }, []);

  return <div className={emptyLayoutClass}>{children}</div>;
};

export { EmptyLayout };
