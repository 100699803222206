import * as React from 'react';

import { Button } from '@zerintia/powerstone-ui';

interface TableTitleButtonProps {
  label: string | JSX.Element;
  icon: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const TableTitleButton: React.FC<TableTitleButtonProps> = ({
  label,
  icon,
  onClick,
  className,
}) => {
  return (
    <Button onClick={onClick} color="primary" size="sm" className={className}>
      {React.cloneElement(icon, { className: 'mr-1' })}
      {label}
    </Button>
  );
};

export default TableTitleButton;
