import * as React from 'react';
import {
  useAsyncDebounce,
  useTable,
  useFilters,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  useGroupBy,
  useExpanded,
} from 'react-table';
import { Table as RSTable, PageLoader } from '@zerintia/powerstone-ui';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';
import { DropDownActions } from './components/DropDownActions';
import {
  handleZerintiaColumns,
  ZerintiaColumn,
  ZerintiaActionsColumn,
  ApiParams,
  ResourcesProps,
} from './utils';
import TableNabvar from './components/TableNavbar';
import TableColumnSelector from './components/ColumnSelector';
import IndeterminateCheckbox from './components/IntermediateCheckbox';

import style from './style.module.css';

interface TableWithoutPaginationProps {
  data: any[];
  columnsDef: (ZerintiaColumn & Record<string, any>)[];
  actionsColumn?: ZerintiaActionsColumn[];
  resources?: ResourcesProps[];
  count: number;
  delay?: number;
  isLoading: boolean;
  defaultParams?: ApiParams;
  resizableColumns?: boolean;
  selectableColumns?: boolean;
  rowSelection?: boolean;
  canGroup?: boolean;
  onRowsSelected?: (rows: any[]) => void;
  responsive?: boolean;
  scrollable?: boolean;
  maxHeight?: number;
  rowProps?: any;
  containerClassName?: any;
  renderRowSubComponent?: (row: any) => React.ReactNode;
  onTableChange?: (params: ApiParams) => void;
}

const TableWithoutPagination: React.FC<TableWithoutPaginationProps> = ({
  data,
  columnsDef,
  actionsColumn,
  defaultParams,
  resources,
  resizableColumns = false,
  selectableColumns = false,
  rowSelection = false,
  onRowsSelected = () => {},
  responsive = false,
  isLoading = false,
  scrollable = false,
  maxHeight = 0,
  rowProps,
  containerClassName = '',
  renderRowSubComponent,
  onTableChange,
}) => {
  const [actionWidth, setActionWidth] = React.useState(-1);
  const { reactTableColumns } = React.useMemo(
    () => handleZerintiaColumns(columnsDef, defaultParams),
    [columnsDef, defaultParams]
  );
  const columns = React.useMemo(() => reactTableColumns, [columnsDef]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows,
    visibleColumns,
    // @ts-ignore
    state: { filters, sortBy, selectedRowIds },
    allColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultParams?.sort ? [{
          id: defaultParams.sort.startsWith('-') ? defaultParams.sort.substring(1) : defaultParams.sort,
          desc: defaultParams.sort.startsWith('-'),
        }] : [],
        filters: Array.isArray(defaultParams?.filters) ? defaultParams?.filters.map((filter, index) => {
          if (Array.isArray(defaultParams?.values) && defaultParams?.values[index]) {
            return {
              id: filter,
              value: defaultParams?.values[index],
            };
          } else {
            return undefined;
          }
        }).filter((v): v is { id: string; value: string; } => Boolean(v)) : [],
      },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    (hooks: any) => {
      rowSelection &&
        hooks.visibleColumns.push((columns: any[]) =>
          [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }: any) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }: any) => (
                <div>
                  <IndeterminateCheckbox
                    row={row}
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
          ].concat(columns)
        );
    }
  );

  const onFetchDataDebounced = useAsyncDebounce(
    (tableState) => {
      // const params = handleTableChange(tableState);
      let params: any = {};
      if (tableState.sortBy[0]) {
        params.sort = (tableState.sortBy[0].desc ? '-' : '') + tableState.sortBy[0].id;
      }
      if (tableState.filters.length > 0) {
        params.filters = tableState.filters.map((filter: any) => filter.id);
        params.values = tableState.filters.map((filter: any) => filter.value);
      }
      onTableChange && onTableChange(params);
    },
    200
  );

  React.useEffect(() => {
    if (onTableChange) {
      onFetchDataDebounced({ filters, sortBy });
    }
  }, [onTableChange, useAsyncDebounce, filters, sortBy]);

  React.useEffect(() => {
    onRowsSelected(selectedFlatRows.map((d: any) => d.original));
  }, [selectedRowIds]);

  React.useLayoutEffect(() => {
    if (!isLoading) {
      const localActionWidth = Math.max(
        ...Array.from(document.getElementsByClassName('zDropDownActions')).map(
          (item: any) => item.offsetWidth
        )
      );
      if (localActionWidth > 0) {
        setActionWidth(localActionWidth);
      }
    }
  }, [isLoading]);

  return (
    <div
      className={`${style.table} ${style.tablescroll} ${containerClassName}`}
    >
      {isLoading && <PageLoader />}
      {selectableColumns && (
        <TableNabvar>
          <TableColumnSelector allColumns={allColumns} />
        </TableNabvar>
      )}
      <RSTable
        {...getTableProps()}
        hover
        responsive={responsive}
        className="text-inverse"
        style={
          scrollable && maxHeight > 0
            ? {
                display: 'block',
                overflow: 'auto',
                width: '100%',
                maxHeight: `${maxHeight}px`,
              }
            : {}
        }
      >
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="font-weight-bold">
                  {column.canGroupBy ? (
                    <span {...column.getGroupByToggleProps()}>
                      {column.isGrouped ? (
                        <small>
                          <FontAwesomeIcon
                            icon={'layer-group'}
                            className="text-primary mr-1"
                          />
                        </small>
                      ) : (
                        <small>
                          <FontAwesomeIcon
                            icon={'layer-group'}
                            className="text-muted mr-1"
                          />
                        </small>
                      )}
                    </span>
                  ) : null}
                  <span {...column.getSortByToggleProps({ title: undefined })}>
                    {column.render('Header')}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          className="ml-1 text-muted"
                          icon={'caret-down'}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="ml-1 text-muted"
                          icon={'caret-up'}
                        />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                  {resizableColumns && (
                    <div
                      {...column.getResizerProps()}
                      className={`${style.resizer} ${
                        column.isResizing ? style.isResizing : ''
                      }`}
                    />
                  )}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
              {actionsColumn?.length && (
                <th
                  style={
                    actionWidth > 0
                      ? {
                          boxSizing: 'content-box',
                          maxWidth: actionWidth,
                          minWidth: actionWidth,
                          width: actionWidth,
                        }
                      : {}
                  }
                >
                  <span></span>
                </th>
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, index: number) => {
            prepareRow(row);
            return (
              <>
                <tr
                  {...row.getRowProps(
                    typeof rowProps === 'function' ? rowProps(row) : rowProps
                  )}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.isGrouped ? (
                          <>
                            <small {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? (
                                <FontAwesomeIcon icon={'chevron-down'} />
                              ) : (
                                <FontAwesomeIcon icon={'chevron-right'} />
                              )}
                            </small>{' '}
                            {cell.render('Cell', { editable: false })} (
                            {row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : (
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                  {actionsColumn?.length && (
                    <td
                      style={
                        actionWidth > 0
                          ? {
                              boxSizing: 'content-box',
                              maxWidth: actionWidth,
                              minWidth: actionWidth,
                              width: actionWidth,
                            }
                          : {}
                      }
                    >
                      <DropDownActions
                        actions={actionsColumn}
                        row={row}
                        rowIndex={index}
                        resources={resources}
                      />
                    </td>
                  )}
                </tr>
                {row.isExpanded && renderRowSubComponent ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </RSTable>
    </div>
  );
};

export default TableWithoutPagination;
