import React, { useState } from 'react';
import Thumbnails from './Thumbnails';
import './CarouselWithThumbnails.css';
import {
  Carousel,
  CarouselControl,
  CarouselItem,
  CarouselProps,
  CarouselItemProps,
  CarouselControlProps,
} from '../../index';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';

interface ImageProps {
  alt: string;
  url: string;
}
interface CarouselWithThumbnailsProps extends CarouselProps {
  images: ImageProps[];
  height: number | string;
  carouselItemProps: CarouselItemProps;
  carouselControlProps: CarouselControlProps;
}
const CarouselWithThumbnails = ({
  images,
  height,
  carouselItemProps,
  carouselControlProps,
  ...rest
}: CarouselWithThumbnailsProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const itemLength = images.length - 1;

  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itemLength - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemLength ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  return (
    <div
      className="d-flex flex-column"
      style={{ height: typeof height === 'string' ? height : `${height}px` }}
    >
      {images && images.length ? (
        <>
          <div style={{ height: '80%' }} className='d-flex'>
            <Carousel
              {...rest}
              activeIndex={activeIndex}
              slide={rest.slide ? rest.slide : true}
              next={nextButton}
              previous={previousButton}
              interval={rest.interval ? rest.interval : false}
              className="w-100"
            >
              {images.map((image, index) => (
                <CarouselItem
                  {...carouselItemProps}
                  onExited={() => setAnimating(false)}
                  onExiting={() => setAnimating(true)}
                  className={`${carouselItemProps?.className} h-100`}
                  key={index}
                >
                  <img
                    alt={image.alt}
                    src={image.url}
                    className="rounded-sm h-100"
                    width="100%"
                    style={{ objectFit: 'contain' }}
                  />
                </CarouselItem>
              ))}

              <CarouselControl
                {...carouselControlProps}
                direction="prev"
                directionText="Previous"
                onClickHandler={previousButton}
              />
              <CarouselControl
                {...carouselControlProps}
                direction="next"
                directionText="Next"
                onClickHandler={nextButton}
              />
            </Carousel>
          </div>
          <Thumbnails
            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            style={{ height: '20%' }}
          ></Thumbnails>
        </>
      ) : (
        <FontAwesomeIcon
          icon={'image'}
          className="text-secondary-01 m-auto"
          size={'10x'}
        ></FontAwesomeIcon>
      )}
    </div>
  );
};

export { CarouselWithThumbnails, CarouselWithThumbnailsProps, ImageProps };
