import * as React from 'react';
import moment from 'moment';
import { DateRangePicker } from '@zerintia/powerstone-ui';

import { FilterProps } from './FilterProps.interface';

export const DateRangeColumnFilter: React.FC<FilterProps> = ({
  column: { setFilter, id, filterProps },
}) => {
  const {
    yearsBefore = 5,
    onChange,
    displayFormat,
    defaultValue,
  } = filterProps || {};

  return (
    <DateRangePicker
      displayFormat={displayFormat}
      defaultValue={defaultValue}
      isOutsideRange={(day) =>
        day.isBefore(moment().subtract(yearsBefore, 'years'))
      }
      startDateId={`startDate_${id}`}
      endDateId={`endDate_${id}`}
      onChange={(startDate, endDate) => {
        onChange?.({
          startDate,
          endDate,
        });
        setFilter({
          startDate,
          endDate,
        });
      }}
      small
    />
  );
};
