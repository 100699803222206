import { getApi } from '../services/api';
import { useUserSessionState } from '../contexts/session';

export function useLogout(managementFrontUri: string) {

  const { managementUri } = useUserSessionState();
  const logout = async() => {
    await getApi(managementUri).logOut();
    sessionStorage.clear();
    window.location.href = `${managementFrontUri}/pages/login`;
  };

  return logout;
}
