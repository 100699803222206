import * as React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { FormText, FileInput, FileInputProps } from '@zerintia/powerstone-ui';

interface ZFileInputControllerProps extends FileInputProps {
  name: string;
}

const ZFileInputController: React.FC<ZFileInputControllerProps> = ({
  files,
  name,
  onChange,
  ...fileInputProps
}) => {
  const { errors } = useFormContext();

  return (
    <>
      <Controller
        defaultValue={files || []}
        name={name}
        render={({ onChange: onFormChange }: any) => (
          <FileInput
            {...fileInputProps}
            id={name}
            invalid={Boolean(get(errors, name))}
            files={files}
            onChange={(res: any) => {
              onFormChange(res);
              onChange && onChange(res);
              return res;
            }}
          />
        )}
      />
      {get(errors, name) && (
        <FormText color="danger">{get(errors, `${name}.message`)}</FormText>
      )}
    </>
  );
};

export default ZFileInputController;
