import React from 'react';
import { Link } from 'react-router-dom';

interface SidebarMenuItemLinkProps {
  to?: string;
  href?: string;
  active?: boolean;
  onToggle?: Function;
  onClick?: Function;
  classBase?: string;
  children?: React.ReactNode;
}
/**
 * Renders a collapse trigger or a ReactRouter Link
 */
export const SidebarMenuItemLink: React.FC<SidebarMenuItemLinkProps> = ({
  to,
  href,
  classBase,
  onClick = () => {},
  onToggle = () => {},
  children,
}) =>
  to || href ? (
    to ? (
      <Link to={to} className={`${classBase}__entry__link`}>
        {children}
      </Link>
    ) : (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={`${classBase}__entry__link`}
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        {children}
      </a>
    )
  ) : (
    <a
      href="#"
      className={`${classBase}__entry__link`}
      onClick={(e) => {
        e.preventDefault();
        onToggle();
      }}
    >
      {children}
    </a>
  );
