export interface ApiParams {
  filters?: string[];
  values?: string[];
  sort?: string;
  populate?: string;
  select?: string;
  models?: string[];
  keys?: string[];
  values_keys?: string[];
  fields?: string[];
  limit?: number;
  offset?: number;
}

export interface ZerintiaColumn {
  id?: string;
  accessor: any;
  filterType?:
    | 'TEXT'
    | 'NUMBER'
    | 'SELECT'
    | 'BOOLEAN'
    | 'EXIST'
    | 'DATERANGE'
    | 'IN_QUERY';
  Header: string | Function;
  Footer?: string | Function;
  Filter?: Function;
  Cell?: Function;
  columns?: this[];
  filterProps?: any;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  disableFilters?: boolean;
  defaultCanFilter?: boolean;
  defaultCanSort?: boolean;
  disableSortBy?: boolean;
  disableGroupBy?: boolean;
  isSecondLevelFilter?: boolean;
}

export interface ZerintiaActionsColumn {
  icon: any;
  name: string;
  onClick: Function;
  predicate: Function;
}

interface actionsProps {
  manage?: boolean;
  delete?: boolean;
  view?: boolean;
  menu?: boolean;
}
export interface ResourcesProps {
  name: string;
  actions: actionsProps;
}

interface ZerintiaFilterParams {
  filters: string[];
  values: string[];
}

type ToFilterFn = () => ZerintiaFilterParams;

function convertValue(
  filterType: string,
  field: string,
  value: string | Object
) {
  const convertions: Record<string, ToFilterFn> = {
    TEXT: () => {
      return { filters: [field], values: [`^${value}`] };
    },
    EXIST: () => {
      return { filters: [field], values: [`*${value}`] };
    },
    SELECT: () => {
      return { filters: [field], values: [`|${value}`] };
    },
    NUMBER: () => {
      const { numberValue, comparator }: any = value;

      return {
        filters: [field],
        values: [comparator === '=' ? numberValue : comparator + numberValue],
      };
    },
    DATERANGE: () => {
      const { startDate, endDate }: any = value;

      return {
        filters: [startDate && field, endDate && field].filter(Boolean),
        values: [
          startDate && `>${startDate.toISOString()}`,
          endDate && `<${endDate?.toISOString()}`,
        ].filter(Boolean),
      };
    },
    IN_QUERY: () => {
      return {
        filters: [field],
        values: [value.toString()],
      };
    },
    default: () => {
      return {
        filters: [field],
        values: [value.toString()],
      };
    },
  };

  return (convertions[filterType] || convertions.default)();
}

function keyBy(arr: Record<string, any>[], keys: [string, string]) {
  const [id, accessor] = keys;

  return arr.reduce((acc, column) => {
    acc[column[id] || column[accessor]] = column;

    return acc;
  }, {} as Record<string, any>);
}

function handleFilter(
  filters = [],
  columnsByAccessor: any,
  defaultParams?: ApiParams
) {
  return filters.reduce(
    (acc: any, filter: any) => {
      const { id, value } = filter;
      const isSecondLevelFilter = columnsByAccessor[id].isSecondLevelFilter;
      const newValue = convertValue(
        columnsByAccessor[id].filterType,
        id,
        value
      );

      if (isSecondLevelFilter && id.includes('.')) {
        const [paramField, paramKey] = id.split('.');
        const model = columnsByAccessor[id].model;

        acc.models.push(model || paramField);
        acc.keys.push(paramKey);
        acc.fields.push(paramField);
        acc.values_keys.push(...newValue.values);
        acc.valuesKeys.push(...newValue.values);

        return acc;
      }

      acc.filters.push(...newValue.filters);
      acc.values.push(...newValue.values);

      return acc;
    },
    {
      filters: [...(defaultParams?.filters || [])],
      values: [...(defaultParams?.values || [])],
      models: [...(defaultParams?.models || [])],
      keys: [...(defaultParams?.keys || [])],
      values_keys: [...(defaultParams?.values_keys || [])],
      valuesKeys: [...(defaultParams?.values_keys || [])],
      fields: [...(defaultParams?.fields || [])],
    }
  );
}

function handleSort(sortBy = []) {
  return sortBy.reduce((acc: any, sortItem: any) => {
    const sorted = sortItem.desc ? `-${sortItem.id}` : sortItem.id;
    if (!acc.sort) {
      acc.sort = sorted;
    } else {
      acc.sort = acc.sort + ',' + sorted;
    }

    return acc;
  }, {});
}

export const handleZerintiaColumns = (
  columns: any[],
  defaultParams?: ApiParams
) => {
  const columnsByAccessor = keyBy(columns, ['id', 'accessor']);

  const handleTableChange = (changesData: Record<string, any> = {}) => {
    const { filters, sortBy, pageSize, pageIndex } = changesData;

    const filtered = handleFilter(filters, columnsByAccessor, defaultParams);
    const sorted = handleSort(sortBy);

    return {
      ...defaultParams,
      ...filtered,
      ...sorted,
      limit: pageSize,
      offset: pageIndex * pageSize,
    };
  };

  return {
    handleTableChange,
    reactTableColumns: columns.map(({ filterType, ...rest }) => ({
      ...rest,
    })),
  };
};
