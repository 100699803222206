import React from 'react';

export interface PageConfigContextType {
  sidebarHidden: boolean;
  navbarHidden: boolean;
  footerHidden: boolean;
  sidebarCollapsed: boolean;
  screenSize: string;
  animationsDisabled: boolean;
  pageTitle: any | null;
  pageDescription: string;
  pageKeywords: string;
  sidebarSlim: boolean;
  crumbs?: Array<{ name: string; path: string, state?: Record<string, any> }>;
  updateCrumbs: (crumbs: Array<{ name: string; path: string, state?: Record<string, any> }>) => void;
  toggleSidebar: (event?: any) => void;
  setElementsVisibility: (elements: {
    sidebarHidden?: boolean;
    navbarHidden?: boolean;
    footerHidden?: boolean;
  }) => void;
  changeMeta: (arg: Record<string, any>) => void;
}

const PageConfigContext = React.createContext<
  PageConfigContextType | undefined
>(undefined);

export { PageConfigContext };
