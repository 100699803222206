import anime from 'animejs';

class SideMenuAnimate {
  public option: Record<string, any>;
  private _nodesObserver: MutationObserver;

  constructor(config?: Record<string, any>) {
    this.option = Object.assign(
      {},
      {
        easing: 'easeInOutCubic',
        duration: 300,
      },
      config
    );

    this._nodesObserver = new MutationObserver((mutations) => {
      const changedNodes = mutations
        .filter((mutation: any) => {
          return (
            mutation.target.classList.contains('sidebar-menu__entry--nested') ||
            mutation.target.classList.contains('sidebar-submenu__entry--nested')
          );
        })
        .map((mutation: any) => {
          return {
            target: mutation.target,
            wasOpen: mutation.oldValue.indexOf('open') >= 0,
          };
        });

      changedNodes.forEach((node) => {
        const isOpen = node.target.classList.contains('open');
        let activeAnimation: any;

        if (isOpen !== node.wasOpen) {
          const menu = node.target.querySelector('.sidebar-submenu');

          if (activeAnimation && !activeAnimation.completed) {
            activeAnimation.reset();
          }

          activeAnimation = anime({
            targets: menu,
            height: isOpen ? [0, menu.scrollHeight] : [menu.scrollHeight, 0],
            duration: this.option.duration,
            easing: this.option.easing,
          });
          activeAnimation.finished.then(() => {
            menu.style.height = '';
          });
        }
      });
    });
  }

  /**
   * Assigns the parent sidebar element, and attaches a Mutation Observer
   * which watches the coallapsable nodes inside of the sidebar menu
   * and animates them on chenages
   *
   * @param {HTMLElement} parentElement SidebarMenu parent
   */
  assignParentElement(parentElement: HTMLElement) {
    this._nodesObserver.disconnect();
    this._nodesObserver.observe(parentElement, {
      attributes: true,
      attributeFilter: ['class'],
      attributeOldValue: true,
      subtree: true,
    });
  }

  destroy() {
    this._nodesObserver.disconnect();
  }
}

export default SideMenuAnimate;
