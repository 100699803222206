import React, { useEffect, useState } from 'react';

import { useFormContext, Controller, useController } from 'react-hook-form';
import get from 'lodash/get';
import map from 'lodash/map';

import { Typeahead, FormText, TypeaheadProps } from '@zerintia/powerstone-ui';

interface ZTypeaheadProps extends TypeaheadProps<never> {
  name: string;
  valueKey?: string;
  defaultValue?: any;
  value?: any;
  onChange?: ((selected: never[]) => void) | undefined;
  clearButton?: boolean;
  multiple?: boolean;
  searchOptions?: Function;
  emptyLabel?: string;
  labelKey: any;
  placeholder?: string;
  size?: 'large' | 'lg' | 'small' | 'sm';
  options: never[];
  disabled?: boolean;
  controlled?: boolean;
  onBlur?: ((e: Event) => void) | undefined;
}

const ZTypeahead: React.FC<ZTypeaheadProps> = ({
  name,
  valueKey,
  defaultValue,
  onChange = () => {},
  multiple,
  searchOptions,
  emptyLabel,
  labelKey,
  placeholder,
  size,
  options,
  clearButton,
  disabled,
  controlled,
  onBlur,
  ...rest
}) => {
  const { errors } = useFormContext();
  const { field } = useController({ name: name });
  const [optionsResult, setOptionsResult] = useState(options || []);
  const [isLoading, setIsLoading] = useState(!!searchOptions);
  const [isInvalid, setIsInvalid] = React.useState(false);

  useEffect(() => {
    async function fetchMyApi() {
      if (searchOptions) {
        setIsLoading(true);
        try {
          const options = await searchOptions();
          setOptionsResult(options);
        } catch (err) {
          setIsInvalid(true);
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      }
    }

    if (searchOptions) {
      fetchMyApi();
    }
  }, [searchOptions]);

  const getDefaultSelected = () => {
    if (multiple) {
      if (valueKey && defaultValue) {
        const selectedOptions =
          optionsResult &&
          optionsResult.filter((item: any) =>
            defaultValue.includes(get(item, valueKey))
          );
        return selectedOptions || [];
      }
      return defaultValue || [];
    } else {
      if (valueKey && defaultValue) {
        const selectedOption =
          optionsResult &&
          optionsResult.find((item) => get(item, valueKey) === defaultValue);
        return selectedOption ? [selectedOption] : [];
      }
      return defaultValue ? [defaultValue] : [];
    }
  };

  return (
    <>
      <Controller
        render={({ onChange: onFormChange }: any) => {
          return (isLoading) ?
          (
            <div>
              <Typeahead
                {...rest}
                ref={field.ref}
                disabled={true}
                isLoading={true}
                size={size}
                id={`${name}-disabled`}
                defaultSelected={getDefaultSelected()}
                labelKey={labelKey}
                multiple={multiple}
                options={[]}
                placeholder={placeholder}
                onBlur={(e: any) => {
                  field.ref.current?.blur();
                  field.onBlur &&  field.onBlur();
                  onBlur && onBlur(e);
                }}
              />
            </div>
          ) :
          (
            <Typeahead
              {...rest}
              ref={field.ref}
              disabled={disabled}
              size={size}
              id={name}
              defaultSelected={!controlled ? getDefaultSelected() : undefined}
              selected={controlled ? getDefaultSelected() : undefined}
              isInvalid={isInvalid || Boolean(get(errors, name))}
              labelKey={labelKey}
              multiple={multiple}
              options={optionsResult}
              placeholder={placeholder}
              emptyLabel={emptyLabel}
              clearButton={clearButton}
              onChange={(selected: any) => {
                if (multiple) {
                  const result = valueKey
                    ? map(selected, valueKey)
                    : selected;
                  onFormChange(result);
                  onChange(selected);
                } else {
                  const result = valueKey
                    ? get(selected?.[0], valueKey)
                    : selected?.[0];
                  onFormChange(result);
                  onChange(selected?.[0]);
                }
              }}
              onBlur={(e: any) => {
                field.ref.current?.blur();
                field.onBlur &&  field.onBlur();
                onBlur && onBlur(e);
              }}
            />
          )
        }}
        defaultValue={defaultValue}
        name={name}
      />
      {get(errors, name) && (
        <FormText color="danger">{get(errors, `${name}.message`)}</FormText>
      )}
    </>
  );
};

export default ZTypeahead;
