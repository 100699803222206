import * as React from 'react';

export const useCssVariables = (cssVariables: string[]) => {
  const [cssValues, setCssValues] = React.useState<string[]>([]);

  React.useEffect(() => {
    const result = cssVariables.map(
      (cssVariable) =>
        getComputedStyle(document.documentElement).getPropertyValue(
          cssVariable
        ) || ''
    );

    setCssValues(result);
  }, []);

  return cssValues;
};
