import * as React from 'react';
import { Input } from '@zerintia/powerstone-ui';

import { FilterProps } from './FilterProps.interface';

export const TextColumnFilter: React.FC<FilterProps> = ({
  column: { filterValue, setFilter, filterProps },
}) => {
  const { placeholder } = filterProps || {};
  const [inputValue, setInputvalue] = React.useState(filterValue);
  return (
    <Input
      bsSize="sm"
      value={inputValue || ''}
      onChange={(e) => {
        const { value } = e.target;
        setInputvalue(value || undefined);
        setFilter(value);
      }}
      placeholder={placeholder}
    />
  );
};
