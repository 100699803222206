import * as React from 'react';

const ThemeContext = React.createContext<
  { color: string; style: string; onChangeTheme: Function } | undefined
>(undefined);

const Provider = ThemeContext.Provider;
const Consumer = ThemeContext.Consumer;

export { Provider, Consumer, ThemeContext };
