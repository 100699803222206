import React from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { useTranslation } from 'react-i18next';

import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@zerintia/powerstone-ui';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';

import style from './style.module.css';

interface Language {
  code: string;
  flag: FlagIconCode;
}

interface NavLanguageSelectorProps {
  languages: Language[];
}

const NavLanguageSelector: React.FC<NavLanguageSelectorProps> = ({
  languages,
}) => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    i18n.language?.toUpperCase()
  );

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle color="link" nav caret>
        {selectedLanguage?.toUpperCase()} <FontAwesomeIcon icon={'globe'} />
      </DropdownToggle>
      <DropdownMenu persist className={style.languageDropdown}>
        {languages.map((language) => {
          return (
            <DropdownItem
              key={`${language.code}-${language.flag}`}
              className={style.languageDropdownItem}
              onClick={() => {
                i18n.changeLanguage(language.code);
                setSelectedLanguage(language.code);
              }}
            >
              <FlagIcon code={language.flag} />
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export { NavLanguageSelector };
