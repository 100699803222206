import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { Input, FormFeedback, InputProps } from '@zerintia/powerstone-ui';

interface ZTextProps extends InputProps {
  name: string;
}

const ZInput: React.FC<ZTextProps> = ({ name, ...rest }) => {
  const { register, errors } = useFormContext();

  return (
    <>
      <Input
        {...rest}
        id={name}
        innerRef={register()}
        invalid={Boolean(get(errors, name))}
        name={name}
      />
      {get(errors, name) && (
        <FormFeedback data-testid="feedback">
          {get(errors, `${name}.message`)}
        </FormFeedback>
      )}
    </>
  );
};

export default ZInput;
