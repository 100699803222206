import React from 'react';

import { Provider } from './ThemeContext';

interface ThemeProviderProps {
  initialStyle?: string;
  initialColor?: string;
  children?: React.ReactNode;
}

interface ThemeProviderState {
  color: string;
  style: string;
}

export class ThemeProvider extends React.Component<
  ThemeProviderProps,
  ThemeProviderState
> {
  constructor(props: ThemeProviderProps) {
    super(props);

    this.state = {
      style: 'light',
      color: 'primary',
    };
  }

  componentDidMount() {
    const { initialStyle, initialColor } = this.props;

    if (initialStyle) {
      this.setState({ style: initialStyle });
    }
    if (initialColor) {
      this.setState({ color: initialColor });
    }
  }

  onChangeTheme(themeState: { style: string; color: string }) {
    this.setState(themeState);
  }

  render() {
    const { children } = this.props;

    return (
      <Provider
        value={{
          ...this.state,
          onChangeTheme: this.onChangeTheme.bind(this),
        }}
      >
        {children}
      </Provider>
    );
  }
}
