import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash-es';
import classNames from 'classnames';
import { usePageConfig } from './../Layout/usePageConfig';
import { MenuContext } from './MenuContext';
import { usePrevious } from '../../../hooks/usePrevious';
import SideMenuAnimate from './SideMenuAnimate';

interface SidebarMenuProps {
  currentUrl?: string;
  slim?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

const SidebarMenu: React.FC<SidebarMenuProps> = (props) => {
  const { slim, disabled, children } = props;
  const { sidebarSlim, sidebarCollapsed, screenSize } = usePageConfig();
  const { location } = useHistory();
  const { pathname } = location;
  const [entries, setEntries] = useState({});
  const [sidebarAnimation, setSidebarAnimation] = useState<any | undefined>();
  const containerRef = useRef() as any;
  const prevPathname = usePrevious(pathname);

  const isSlim =
    slim ||
    (sidebarSlim &&
      sidebarCollapsed &&
      (screenSize === 'lg' || screenSize === 'xl'));
  const sidebarMenuClass = classNames('sidebar-menu', {
    'sidebar-menu--slim': isSlim,
    'sidebar-menu--disabled': disabled,
  });

  const addEntry = (entry: any) => {
    setEntries((prevState) => ({
      ...prevState,
      [entry.id]: {
        open: false,
        active: false,
        ...entry,
      },
    }));
  };

  const updateEntry = (id: any, stateMods: any) => {
    setEntries((prevState: Record<string, any>) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        ...stateMods,
      },
    }));
  };

  const removeEntry = (id: any) => {
    const { [id]: toRemove, ...rest } = entries as any;
    setEntries({ ...rest });
  };

  const setActiveEntries = (openActive = false) => {
    const activeId = (
      childEntry: any,
      entries: any,
      previous: any = []
    ): any => {
      if (childEntry.parentId) {
        const parentEntry = entries[childEntry.parentId];
        const activeIds = [...previous, parentEntry.id];
        return activeId(parentEntry, entries, activeIds);
      }

      return previous;
    };

    const activeChild: any = _.find(entries, (entry: any) => {
      const noTailSlashLocation =
        pathname[pathname.length - 1] === '/' && pathname.length > 1
          ? pathname.replace(/\/$/, '')
          : pathname;

      return entry.exact
        ? entry.url === noTailSlashLocation
        : _.includes(noTailSlashLocation, entry.url);
    });

    if (activeChild) {
      const activeEntries = [...activeId(activeChild, entries), activeChild.id];

      setEntries((prevState) =>
        _.mapValues(prevState, (entry: any) => {
          const isActive = _.includes(activeEntries, entry.id);

          return {
            ...entry,
            active: isActive,
            open: openActive ? !entry.url && isActive : entry.open,
          };
        })
      );
    }
  };

  useEffect(() => {
    const mySidebarAnimation = new SideMenuAnimate();
    mySidebarAnimation.assignParentElement(containerRef.current);

    setSidebarAnimation(mySidebarAnimation);
    setTimeout(() => {
      setActiveEntries(true);
    }, 0);

    return () => {
      if (sidebarAnimation) {
        sidebarAnimation.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (pathname === prevPathname) {
      setActiveEntries(true);
    }
    if (pathname !== prevPathname) {
      setActiveEntries();
    }
  }, [pathname, prevPathname]);

  return (
    <MenuContext.Provider
      value={{
        entries: entries,
        addEntry: addEntry,
        updateEntry: updateEntry,
        removeEntry: removeEntry,
      }}
    >
      <ul className={sidebarMenuClass} ref={containerRef}>
        {React.Children.map(children, (child: any) => (
          <MenuContext.Consumer>
            {(ctx) =>
              React.cloneElement(child, {
                ...ctx,
                currentUrl: pathname,
                slim: isSlim,
              })
            }
          </MenuContext.Consumer>
        ))}
      </ul>
    </MenuContext.Provider>
  );
};

export { SidebarMenu };
