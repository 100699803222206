import * as React from 'react';

function ProcessLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 450 450"
    >
      <defs>
        <clipPath id="clippath">
          <rect y="0" width="450" height="450" rx="70" ry="70" />
        </clipPath>
      </defs>
      <g>
        <rect y="0" width="450" height="450" rx="70" ry="70" />
        <g style={{ clipPath: 'url(#clippath)' }}>
          <rect
            x=".16"
            y="-.07"
            width="450.07"
            height="450.07"
            rx="41.64"
            ry="41.64"
            style={{ fill: '#28a950' }}
          />
          <g>
            <path
              d="m266.09,283.94l229.57,66.95c47.88,13.96,88.48,45.95,113.28,89.22l9.23,16.11c13.59,23.72-3.54,53.27-30.88,53.27H200.23c-27.79,0-47.8-26.67-40.02-53.35l39.57-135.82c8.26-28.36,37.96-44.66,66.32-36.38Z"
              style={{ fill: '#45b068' }}
            />
            <path
              d="m-70.73,234.41l-124.22,221.45c-13.47,24.01,3.89,53.64,31.41,53.64H67.87c32.84,0,61.74-21.68,70.92-53.21l43.38-148.89c8.26-28.35-8.02-58.03-36.37-66.3L9.89,201.47c-31.26-9.12-64.68,4.54-80.62,32.94Z"
              style={{ fill: '#45b068' }}
            />
            <path
              d="m190.39-204.49L5.62,124.93c-13.77,24.56-1.11,55.54,25.92,63.43l126.93,37.02c28.36,8.27,58.06-8.02,66.32-36.38l64.39-221.02c13.39-45.95,7.51-95.38-16.28-136.91l-20.52-35.83c-13.76-24.01-48.44-23.85-61.98.28Z"
              style={{ fill: '#45b068' }}
            />
            <path
              d="m293.85,22.69l-52.14,178.98c-8.3,28.49,8.06,58.31,36.55,66.62l188.53,54.98c26.47,7.72,48.87-20.72,35.17-44.65L351.41,15.84c-13.81-24.1-49.79-19.82-57.56,6.85Z"
              style={{ fill: '#45b068' }}
            />
          </g>
        </g>
        <text transform="translate(78.16 349.42)">
          <tspan
            x="0"
            y="0"
            style={{
              fill: '#fafbfd',
              fontSize: '350px',
              letterSpacing: '-.05em',
              fontFamily: 'RobotoCondensed-Regular, "Roboto Condensed"',
            }}
          >
            4
          </tspan>
          <tspan
            x="155.28"
            y="0"
            style={{
              fill: '#fafbfd',
              fontSize: '250px',
              letterSpacing: '-.05em',
              fontFamily: 'RobotoCondensed-Regular, "Roboto Condensed"',
            }}
          >
            P
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default ProcessLogo;
