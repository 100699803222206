import * as React from 'react';
import { get } from 'lodash-es';
import { Typeahead } from '@zerintia/powerstone-ui';

import { FilterProps } from './FilterProps.interface';

export const TypeaheadColumnFilter: React.FC<FilterProps> = ({
  column: { setFilter, filterProps, id },
}) => {
  const { labelKey, placeholder, options, valueKey, searchOptions } =
    filterProps || {};
  const [optionsResult, setOptionsResult] = React.useState(options || []);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isInvalid, setIsInvalid] = React.useState(false);

  React.useEffect(() => {
    async function fetchMyApi() {
      setIsLoading(true);
      try {
        const options = await searchOptions();
        setOptionsResult(options);
      } catch (err) {
        setIsInvalid(true);
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }

    if (searchOptions) {
      fetchMyApi();
    }
  }, [searchOptions]);

  return (
    <Typeahead
      {...filterProps}
      isLoading={isLoading}
      options={optionsResult}
      size="sm"
      clearButton
      id={id}
      isInvalid={isInvalid}
      placeholder={placeholder}
      onChange={([value]: any) => {
        const parseValue = get(value, valueKey || labelKey);
        setFilter(parseValue);
        filterProps?.onChange?.(parseValue);
      }}
    />
  );
};
