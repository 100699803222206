import React from 'react';
import { NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';
import { usePageConfig } from './../Layout';

import style from './style.module.css';

interface SidebarTriggerProps {
  tag?: any;
  children?: React.ReactNode;
}

const SidebarTrigger: React.FC<SidebarTriggerProps> = (props) => {
  const {
    tag: Tag = NavLink,
    children = <FontAwesomeIcon icon={'bars'} />,
    ...otherProps
  } = props;
  const { toggleSidebar, sidebarCollapsed } = usePageConfig();

  return (
    <Tag
      onClick={toggleSidebar}
      active={Tag !== 'a' ? !sidebarCollapsed : undefined}
      className={style.burguerButton}
      {...otherProps}
    >
      {children}
    </Tag>
  );
};

export { SidebarTrigger };
