import * as React from 'react';

import { PageConfigContext } from './PageConfigContext';

export const usePageConfig = () => {
  const context = React.useContext(PageConfigContext);

  if (context === undefined) {
    throw new Error(
      'usePageConfig must be used within a PageConfigContext, inside AppLayout'
    );
  }

  return context;
};
