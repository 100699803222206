import axios, { AxiosInstance } from 'axios';

const UNAUTHORIZED = 401;

const apiConfig = (() => {
  let instance: AxiosInstance | undefined;

  function init(managementFront: string): AxiosInstance {
    if (!instance) {
      instance = axios.create();
      instance.defaults.withCredentials = true;
      instance.interceptors.response.use(
        (response: any) => response,
        (error: any) => {
          if (error.response.status === UNAUTHORIZED) {
            const { origin, href, pathname } = window.location;
            const urlWithNoRedirectUri = `${origin}${pathname}`;
            const isPageLogin =
              urlWithNoRedirectUri === `${managementFront}/pages/login`;

            const isPageRecoveryPassword = urlWithNoRedirectUri.startsWith(
              `${managementFront}/recovery-password`
            );
            const isManagementFront = !managementFront || href === origin;

            if (!isPageLogin && !isPageRecoveryPassword) {
              if (isPageRecoveryPassword) {
                window.location.href = urlWithNoRedirectUri;
              } else {
                window.location.href = isManagementFront
                  ? `${managementFront}/pages/login`
                  : `${managementFront}/pages/login?redirect_uri=${href}`;
              }
            }
          }

          return Promise.reject(error);
        }
      );

      return instance;
    }

    return instance;
  }

  return {
    instance,
    init,
  };
})();

export default apiConfig;
