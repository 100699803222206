import * as React from 'react';

import { Layout, LayoutContent, LayoutNavbar, LayoutSidebar } from '../Layout';
import { ThemeProvider } from '../Theme';

import { DefaultNavbar } from '../DefaultNavbar';
import { DefaultSidebar } from '../DefaultSidebar';

const favIcons = [
  {
    rel: 'icon',
    type: 'image/x-icon',
    href: '#',
  },

  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '#',
  },

  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '#',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '#',
  },
];

interface AppLayoutProps {
  pageTitle?: string;
  resources: Resource[];
  home?: string;
  navbarAuthComponents: JSX.Element;
  baseHref?: string;
  children?: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  resources,
  home,
  pageTitle,
  navbarAuthComponents,
  baseHref,
}) => {
  return (
    <ThemeProvider initialStyle="dark" initialColor="primary">
      <Layout sidebarSlim favIcons={favIcons} pageTilte={pageTitle}>
        {/* --------- Navbar ----------- */}
        <LayoutNavbar>
          <DefaultNavbar home={home}>{navbarAuthComponents}</DefaultNavbar>
        </LayoutNavbar>
        {/* -------- Sidebar ------------*/}
        <LayoutSidebar>
          <DefaultSidebar resources={resources} baseHref={baseHref} />
        </LayoutSidebar>

        {/* -------- Content ------------*/}
        <LayoutContent>{children}</LayoutContent>
      </Layout>
    </ThemeProvider>
  );
};

export default AppLayout;
