import React, { useState } from 'react';
import reduce from 'lodash/reduce';
import { format, addSeconds } from 'date-fns';
import { Input, FormText, Label } from 'reactstrap';

import style from './style.module.css';

const getInputValues = (seconds?: number) => {
  if (seconds && Number.isInteger(seconds)) {
    return format(addSeconds(new Date(0), seconds), 'hh:mm:ss')
      .split(':')
      .map((durationItem: any, index: number) =>
        Number(index === 0 ? durationItem - 1 : durationItem)
      );
  } else {
    return [0, 0, 0];
  }
};

const sumDurationParts = (result: number, value = 0, key: string) => {
  const parts: Record<string, () => number> = {
    durationInput1: () => result + Number(value) * 3600,
    durationInput2: () => result + Number(value) * 60,
    durationInput3: () => result + Number(value),
  };

  return parts[key]();
};

interface ErrorProps {
  message: string;
}

export interface DurationInputProps {
  seconds?: number;
  errors?: ErrorProps;
  onDurationChange?: any;
  label?: (label: string) => string;
  showSeconds: boolean;
}

export const DurationInput: React.FC<DurationInputProps> = ({
  seconds,
  onDurationChange,
  errors,
  label = (r) => r,
  showSeconds = true,
}) => {
  const [durationInput1, durationInput2, durationInput3] = getInputValues(
    seconds
  );
  const [inputs, setInputs] = useState({
    durationInput1,
    durationInput2,
    durationInput3,
  });

  const handleInputsChange = (e: any) => {
    const { name, value } = e.target;
    let newState: any;

    if (value < 0) {
      newState = { ...inputs, [name]: 0 };
    } else if (
      (name === 'durationInput2' || name === 'durationInput3') &&
      value > 59
    ) {
      newState = { ...inputs, [name]: 59 };
    } else {
      newState = { ...inputs, [name]: value };
    }

    setInputs(() => newState);
    onDurationChange(reduce(newState, sumDurationParts, 0));
  };

  return (
    <>
      <section className="d-flex align-items-baseline">
        <section className={style.inputSize}>
          <Input
            id="durationHours"
            name="durationInput1"
            type="number"
            invalid={Boolean(errors)}
            min={0}
            value={inputs.durationInput1}
            onChange={handleInputsChange}
          />
          <FormText tag={Label} htmlFor="durationHours" color="muted">
            <small>{label('HOURS')}</small>
          </FormText>
        </section>
        <section className={`mr-1 ml-1 ${style.labelContainer}`}>:</section>
        <section className={style.inputSize}>
          <Input
            id="durationMinutes"
            name="durationInput2"
            type="number"
            invalid={Boolean(errors)}
            min={0}
            max={59}
            value={inputs.durationInput2}
            onChange={handleInputsChange}
          />
          <FormText tag={Label} htmlFor="durationMinutes" color="muted">
            <small>{label('MINUTES')}</small>
          </FormText>
        </section>
        {showSeconds && (
          <>
            <section className={`mb-auto mt-1 mr-1 ml-1 ${style.labelContainer}`}>:</section>
            <section className={style.inputSize}>
              <Input
                id="durationSeconds"
                name="durationInput3"
                type="number"
                invalid={Boolean(errors)}
                min={0}
                max={59}
                value={inputs.durationInput3}
                onChange={handleInputsChange}
              />
              <FormText tag={Label} htmlFor="durationSeconds" color="muted">
                <small>{label('SECONDS')}</small>
              </FormText>
            </section>
          </>
        )}
      </section>
      {errors && <FormText color="danger">{errors.message}</FormText>}
    </>
  );
};
