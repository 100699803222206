import { useForm as useRHForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

type UseZFormConfig = {
  validationSchema?: any;
  mode?: 'onBlur' | 'onChange' | 'onSubmit' | 'onTouched' | 'all';
  reValidateMode?: 'onBlur' | 'onSubmit' | 'onChange';
  defaultValues?: Object;
  shouldUnregister?: boolean;
};

export const useZForm = (config: UseZFormConfig = {}) => {
  const { validationSchema, mode, reValidateMode, defaultValues, shouldUnregister } = config;

  const methods = useRHForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    mode,
    reValidateMode,
    defaultValues,
    shouldUnregister,
  });

  return [methods];
};
