import * as React from 'react';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CustomInput,
  PageLoader,
  useToasts,
  Button,
  Select,
} from '@zerintia/powerstone-ui';
import { useTranslation } from 'react-i18next';
import { useUserSession, useUserSessionState } from '../../contexts/session';
import { getApi } from '../../services/api';

const NavbarSites = () => {
  const { t } = useTranslation('core');
  const [loading, setLoading] = React.useState(false);
  const [{ profileId, sites }, dispatch] = useUserSession();
  const { managementUri } = useUserSessionState();
  const { showToast } = useToasts();

  const handleChange = async (e: any) => {
    if (
      sites?.filter(({ selected }) => selected).length === 1 &&
      !e.target.checked
    ) {
      return;
    }

    if (!profileId) {
      return;
    }

    try {
      setLoading(true);
      let activeSites = (sites || [])
        .map((s) => {
          if (s._id === e.target.id) {
            s.selected = e.target.checked;
          }
          return s;
        })
        .filter(({ selected }) => selected)
        .map((s) => s._id);
      if (activeSites.length === (sites || []).length) {
        activeSites = [];
      }
      await getApi(managementUri).updateActiveSites(profileId, {
        activeSites,
      });
    } catch (err) {
      e.target.checked = !e.target.checked;
      showToast('error', t('ERROR_ON_SET_ACTIVE_SITES'));
    } finally {
      setLoading(false);
    }

    dispatch({
      type: 'updateSite',
      payload: { _id: e.target.id, selected: e.target.checked },
    });
  };

  const handleSelectAll = async () => {
    if (!profileId) {
      return;
    }
    try {
      setLoading(true);
      const activeSites: any[] = [];
      await getApi(managementUri).updateActiveSites(profileId, {
        activeSites,
      });
    } catch (err) {
      showToast('error', t('ERROR_ON_SET_ACTIVE_SITES'));
    } finally {
      setLoading(false);
    }
    dispatch({
      type: 'selectAllSites',
      payload: {},
    });
  };

  const getOneSelected = () => {
    return sites?.filter(({ selected }) => selected).length === 1
      ? sites.find(({ selected }) => selected)?._id
      : '';
  };

  const handleSelectOne = async (e: any) => {
    const activeSites = [e.target.value];
    if (!profileId) {
      return;
    }
    try {
      setLoading(true);
      await getApi(managementUri).updateActiveSites(profileId, {
        activeSites,
      });
    } catch (err) {
      showToast('error', t('ERROR_ON_SET_ACTIVE_SITES'));
    } finally {
      setLoading(false);
    }
    dispatch({
      type: 'selectOneSite',
      payload: { _id: activeSites[0] },
    });
  };

  return (
    <>
      {loading && <PageLoader />}
      <UncontrolledButtonDropdown>
        <DropdownToggle color="link" nav caret>
          <FontAwesomeIcon icon={'home'} />{' '}
          {sites?.filter(({ selected }) => selected).length === 1
            ? sites.find(({ selected }) => selected)?.name
            : t('VARIOUS_SITES')}
        </DropdownToggle>
        <DropdownMenu persist>
          <DropdownItem header>{t('SELECT_SITES')}</DropdownItem>
          <DropdownItem divider />
          {sites && sites?.length > 1 && (
            <>
              <DropdownItem key={'all'}>
                <Button
                  type="button"
                  className="btn-block"
                  color="primary"
                  id={'all'}
                  onClick={handleSelectAll}
                >
                  {t('SELECT_SITES').split(' ')[0] +
                    ' ' +
                    t('ALL').toLocaleLowerCase()}
                </Button>
              </DropdownItem>
              <DropdownItem divider />
              <div className="container">
                <Select
                  name="select_one"
                  options={sites}
                  placeholder={t('SELECT_SITES').slice(0, -1)}
                  labelKey={'name'}
                  value={getOneSelected()}
                  valueKey={'_id'}
                  onChange={handleSelectOne}
                />
              </div>
              <DropdownItem divider />
            </>
          )}
          {sites?.map((site) => (
            <DropdownItem key={site._id}>
              <CustomInput
                type="checkbox"
                id={site._id}
                label={site.name}
                checked={site.selected}
                onChange={handleChange}
              />
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </>
  );
};

export default NavbarSites;
