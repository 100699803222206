import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  useToasts,
  PageLoader,
  Label,
  FormGroup,
} from '@zerintia/powerstone-ui';
import {
  useZForm,
  ZForm,
  ZInput,
  SubmitSection,
} from '@zerintia/powerstone-form';
import { getApi } from '../../../../services/api';
import sessionStorageService from '../../../../services/sessionStorage';
import { useUserSessionState } from '../../../../contexts/session';

interface UserChangePinProps {
  userId: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const UserChangePin: React.FC<UserChangePinProps> = ({
  userId,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const { managementUri } = useUserSessionState();
  const { t } = useTranslation('core');
  const { showToast } = useToasts();
  const [loading, setLoading] = React.useState(false);

  const { pin_requirements } = sessionStorageService.getLoginTypes() || {
    pin_requirements: {
      min: 4,
      max: 4,
      maxseq: 4,
    },
  };

  const checkPasswordFormat = function (
    pwd: any,
    options: any
  ): { ok: boolean; err: string } {
    if (!pwd) return { ok: false, err: t('REQUIRED') };

    // Counts
    const min = options.hasOwnProperty('min') ? options.min : 10;
    const max = options.hasOwnProperty('max') ? options.max : 10;
    const dig = options.hasOwnProperty('dig') ? options.dig : 1;
    const upp = options.hasOwnProperty('upp') ? options.upp : 1;
    const low = options.hasOwnProperty('low') ? options.low : 1;
    const spe = options.hasOwnProperty('spe') ? options.spe : 1;
    const maxseq = options.hasOwnProperty('maxseq') ? options.maxseq : 3;

    const count = { digit: 0, upper: 0, lower: 0, special: 0 };
    let seq = 1;
    let ms = 1;

    // Length
    if (pwd.length < min)
      return { ok: false, err: t('PASSWORD_LENGTH_LESS', {min}) };
    if (pwd.length > max)
      return { ok: false, err: t('PASSWORD_LENGTH_MORE', {max}) };

    // Verify password content
    for (let i = 0; i < pwd.length; i++) {
      // Count char types
      const c = pwd.charCodeAt(i); // Char
      if (c > 47 && c < 58) count.digit++;
      // Digits
      else if (c > 64 && c < 91) count.upper++;
      // Uppercase letter
      else if (c > 96 && c < 123) count.lower++;
      // Lowercase letter
      else count.special++; // Special

      // Count consecutive chars
      if (i > 0) {
        if (c == pwd.charCodeAt(i - 1)) {
          seq++;
          if (seq > ms) ms = seq;
        } else {
          seq = 1;
        }
      }
    }

    // Check
    if (count.digit < dig)
      return {
        ok: false,
        err: t('PASSWORD_DIGITS', {dig}),
      };
    if (count.upper < upp)
      return {
        ok: false,
        err: t('PASSWORD_UPP', {upp}),
      };
    if (count.lower < low)
      return {
        ok: false,
        err: t('PASSWORD_LOW', {low}),
      };
    if (count.special < spe)
      return {
        ok: false,
        err: t('PASSWORD_SPE', {spe}),
      };
    if (ms > maxseq)
      return {
        ok: false,
        err:t('PASSWORD_CONSECUTIVE', {maxseq}),
      };

    // Ok
    return { ok: true, err: '' };
  };

  const UserChangePinSchema = yup.object().shape({
    oldPin: yup.string().required(t('REQUIRED')),
    newPin: yup
      .string()
      .test(
        'password',
        (value) => checkPasswordFormat(value.value, pin_requirements).err,
        (value) => checkPasswordFormat(value, pin_requirements).ok
      )
      .required(t('REQUIRED')),
    checkingPin: yup
      .string()
      .test(
        'password',
        (value) => checkPasswordFormat(value.value, pin_requirements).err,
        (value) => checkPasswordFormat(value, pin_requirements).ok
      )
      .required(t('REQUIRED')),
  });

  const [form] = useZForm({ validationSchema: UserChangePinSchema });

  const getErrorMessage = (err: any = {}) => {
    const { code } = err.response?.data || {};

    const errorCodes: Record<string, any> = {
      2: t('PASSWORD_CAN_NOT_BE_THE_PREVIOUS'),
      3: t('INVALID_OLD_PASSWORD'),
      default: t('ERROR_ON_PIN_REGENERATION'),
    };

    return errorCodes[code] || errorCodes.default;
  };

  const regeneratePassword = async (data: Record<string, any>) => {
    setLoading(true);
    try {
      await getApi(managementUri).regeneratePin(userId, data);

      showToast('success', t('PIN_GENERATED'));
      onSubmit();
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      showToast('error', t(errorMessage));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (data: Record<string, any>) => {
    const { oldPin, newPin, checkingPin } = data;

    if (checkingPin !== newPin) {
      form.setError('checkingPin', {
        type: 'manual',
        message: t('PIN_NOT_MATCH'),
      });

      return;
    }

    regeneratePassword({
      adminUpdate: false,
      oldPin,
      newPin,
    });
  };

  return (
    <>
      {loading && <PageLoader />}
      <ZForm form={form} onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="oldPin">{t('PIN')}</Label>
          <ZInput name="oldPin" placeholder={t('PIN')} type="password" />
        </FormGroup>
        <div className="mb-2">
          <span>
            <i>
              {t('PIN_RESTRICTIONS', {
                min: pin_requirements.min,
                max: pin_requirements.max,
                maxseq: pin_requirements.maxseq,
              })}
            </i>
          </span>
        </div>
        <FormGroup>
          <Label htmlFor="newPin">{t('NEW_PIN')}</Label>
          <ZInput name="newPin" placeholder={t('NEW_PIN')} type="password" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="checkingPin">{t('REPEAT_PIN')}</Label>
          <ZInput
            name="checkingPin"
            placeholder={t('REPEAT_PIN')}
            type="password"
          />
        </FormGroup>
        <SubmitSection
          submitLabel={t('ACCEPT')}
          cancelLabel={t('CANCEL')}
          onCancel={onCancel}
        />
      </ZForm>
    </>
  );
};

export default UserChangePin;
