import * as React from 'react';

interface TableTitleSectionProps {
  title: string | JSX.Element;
  children?: React.ReactNode;
}

const TableTitleSection: React.FC<TableTitleSectionProps> = ({
  title,
  children,
}) => {
  return (
    <section className="d-flex justify-content-between align-items-center mb-2">
      <h4 className="m-0">{title}</h4>
      <section>{children}</section>
    </section>
  );
};

export default TableTitleSection;
