import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  withZModal,
  ZModalActionsProps,
} from '@zerintia/powerstone-ui';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@zerintia/powerstone-icons';
import UserChangePassword from './components/UserChangePassword';
import UserChangePin from './components/UserChangePin';
import UserQr from './components/UserQr';
import { useUserSessionState } from '../../contexts/session';

import style from './style.module.css';

interface NavbarUserProps extends ZModalActionsProps {
  onLogout?: () => void;
  onSwitchProfile?: () => void;
}

const NavbarUser: React.FC<NavbarUserProps> = ({
  onLogout,
  onSwitchProfile,
  openModal,
  closeModal,
}) => {
  const { t } = useTranslation('core');
  const { user } = useUserSessionState();

  const handleChangePassword = () => {
    openModal({
      modalTitle: t('CHANGE_PASSWORD'),
      Icon: <FontAwesomeIcon icon={'lock'} />,
      component: (
        <UserChangePassword
          userId={user?.id || ''}
          onSubmit={closeModal}
          onCancel={closeModal}
        />
      ),
    });
  };

  const handleChangePin = () => {
    openModal({
      modalTitle: t('CHANGE_PIN'),
      Icon: <FontAwesomeIcon icon={'lock'} />,
      component: (
        <UserChangePin
          userId={user?.id || ''}
          onSubmit={closeModal}
          onCancel={closeModal}
        />
      ),
    });
  };

  const handleViewQr = () => {
    openModal({
      modalTitle: t('VIEW_QR'),
      Icon: <FontAwesomeIcon icon={'qrcode'} />,
      component: <UserQr userId={user?.id || ''} />,
    });
  };

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle color="link" nav caret>
        <FontAwesomeIcon icon={'user'} />{' '}
        {user ? `${user.first_name} ${user.last_name}` : '-'}
      </DropdownToggle>
      <DropdownMenu right>
        {user && user.profiles.length > 1 ? (
          <DropdownItem onClick={onSwitchProfile}>
            <FontAwesomeIcon icon={'users'} className={style.minIconWidth} />{' '}
            {t('SWITCH_PROFILE')}
          </DropdownItem>
        ) : null}
        <DropdownItem onClick={handleChangePassword}>
          <FontAwesomeIcon icon={'lock'} className={style.minIconWidth} />{' '}
          {t('CHANGE_PASSWORD')}
        </DropdownItem>
        <DropdownItem onClick={handleChangePin}>
          <FontAwesomeIcon icon={'lock'} className={style.minIconWidth} />{' '}
          {t('CHANGE_PIN')}
        </DropdownItem>
        <DropdownItem onClick={handleViewQr}>
          <FontAwesomeIcon icon={'qrcode'} className={style.minIconWidth} />{' '}
          {t('VIEW_QR')}
        </DropdownItem>
        <DropdownItem onClick={onLogout}>
          <FontAwesomeIcon icon={'power-off'} className={style.minIconWidth} />{' '}
          {t('LOGOUT')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

const NavbarUserWithZModal = withZModal(NavbarUser);

export { NavbarUserWithZModal as NavbarUser };
