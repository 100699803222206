import React, { useState, useRef, useCallback, useEffect } from 'react';

import { Badge, InputGroupAddon, Button, InputGroup } from 'reactstrap';

import { FontAwesomeIcon } from '@zerintia/powerstone-icons';

export interface FileInputProps {
  id?: string;
  files?: any;
  multiple?: boolean;
  label?: string;
  onChange?: Function;
  invalid?: boolean;
  disabled?: boolean;
  accept?: [string];
  placeholder?: string;
  removeLabel?: string;
}

export const FileInput: React.FC<FileInputProps> = ({
  files,
  multiple,
  onChange = () => {},
  id,
  placeholder,
  invalid,
  disabled,
  accept = ['*'],
  removeLabel = 'Remove',
}) => {
  const [currentFiles, setCurrentFiles] = useState(() => files || []);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleAddFiles = useCallback(
    (e: any) => {
      const { files: filesValue, value } = e.target;

      if (value !== '') {
        if (multiple) {
          const newFiles = currentFiles.concat(Array.from(filesValue));
          setCurrentFiles(newFiles);
          onChange(newFiles);
        } else {
          const fileResult = filesValue[0];
          setCurrentFiles([fileResult as never]);
          onChange(filesValue[0]);
        }
      }
    },
    [currentFiles, multiple]
  );

  const handleUnselectFile = useCallback(
    (filesValue: any) => {
      const filteredFiles = currentFiles.filter(
        (currentFile: File) => currentFile.name !== filesValue.name
      );
      setCurrentFiles(filteredFiles);
      onChange(filteredFiles);

      if (filteredFiles.length === 0) {
        inputRef.current.value = '';
      }
    },
    [currentFiles]
  );

  useEffect(() => {
    if (inputRef.current && !files) {
      inputRef.current.value = '';
    }

    setCurrentFiles(files || []);
  }, [files]);

  return (
    <>
      <InputGroup>
        {!multiple && currentFiles[0] && (
          <InputGroupAddon addonType="prepend">
            <Button
              color="secondary"
              outline
              onClick={() => {
                handleUnselectFile(currentFiles[0]);
              }}
            >
              {removeLabel}
            </Button>
          </InputGroupAddon>
        )}
        <div className="custom-file">
          <input
            role="button"
            aria-label="fileUpload"
            disabled={disabled}
            ref={inputRef}
            type="file"
            id={id}
            accept={accept.join()}
            className={
              invalid ? `custom-file-input input-invalid` : `custom-file-input`
            }
            onChange={handleAddFiles}
          />
          <label htmlFor={id} className="custom-file-label">
            {currentFiles.map(({ name }: File) => name).join() || placeholder}
          </label>
        </div>
      </InputGroup>

      {multiple &&
        currentFiles.map((currentFile: File, index: number) => {
          return (
            <div key={`${index}-${currentFile.name}`} className={'inline ml-1'}>
              <Badge color="primary" className="bigger-badge">
                <span className="color-white">{currentFile.name}</span>
                <FontAwesomeIcon
                  className="ml-2 color-white"
                  onClick={handleUnselectFile.bind(null, currentFile)}
                  style={{ cursor: 'pointer' }}
                  icon="times"
                />
              </Badge>
            </div>
          );
        })}
    </>
  );
};
