import * as React from 'react';
import { isNumber } from 'lodash';
import classNames from 'classnames';

interface EmptyLayoutSectionProps {
  width?: number | string;
  center?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const EmptyLayoutSection: React.FC<EmptyLayoutSectionProps> = ({
  width = 420,
  center,
  className,
  children,
}) => {
  const sectionClass = classNames(className, 'fullscreen__section', {
    'fullscreen__section--center': center,
  });
  const maxWidth = isNumber(width) ? `${width}px` : width;

  return (
    <div className={sectionClass}>
      {center ? (
        <div className="fullscreen__section__child" style={{ maxWidth }}>
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export { EmptyLayoutSection };
