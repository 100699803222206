import * as React from 'react';
import { CustomInput } from '@zerintia/powerstone-ui';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, row, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <CustomInput
          id={row?.id ? `${row.id}-cb` : 'header-cb'}
          type="checkbox"
          innerRef={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

export default IndeterminateCheckbox;
