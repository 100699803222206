import * as React from 'react';
import { Media } from 'reactstrap';

interface HeaderSectionProps {
  className?: string;
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
}

export const HeaderSection: React.FC<HeaderSectionProps> = ({
  className,
  title,
  subTitle,
  children,
}) => (
  <Media className={`mb-3 ${className}`}>
    <Media body>
      <h4 className="mt-1">{title}</h4>
      {children || subTitle ? <p>{children || subTitle}</p> : null}
    </Media>
  </Media>
);
