import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from '@zerintia/powerstone-ui';

import { ZerintiaActionsColumn, ResourcesProps } from '../../utils';

interface DropDownActionsProps {
  actions: ZerintiaActionsColumn[];
  row: Record<string, any>;
  rowIndex: number;
  resources?: ResourcesProps[];
}

export const DropDownActions: React.FC<DropDownActionsProps> = ({
  actions,
  row,
  rowIndex,
}) => {
  const { t } = useTranslation('core');

  const renderAction = React.useMemo(() => {
    const { name, onClick, icon, predicate } = actions.find((action) =>
      action.predicate(row)
    )!;
    const Item = (
      <Button
        color="primary"
        onClick={() => {
          onClick(row, rowIndex);
        }}
        size="sm"
      >
        {React.cloneElement(icon, { className: 'mr-2' })}
        {name}
      </Button>
    );
    return predicate(row) ? Item : null;
  }, [actions, row]);

  const renderActions = React.useMemo(
    () =>
      actions.map(({ name, onClick, icon, predicate }) => {
        const Item = (
          <DropdownItem
            key={`${name}-key`}
            onClick={() => {
              onClick(row, rowIndex);
            }}
          >
            {React.cloneElement(icon, { className: 'mr-2' })}
            {name}
          </DropdownItem>
        );
        return predicate(row) ? Item : null;
      }),
    [actions, row]
  );

  return renderActions.filter(Boolean).length > 1 ? (
    <UncontrolledButtonDropdown
      className="zDropDownActions"
      style={{ float: 'right' }}
    >
      <DropdownToggle size="sm" color="primary" caret>
        {t('ACTIONS')}
      </DropdownToggle>
      <DropdownMenu right>{renderActions}</DropdownMenu>
    </UncontrolledButtonDropdown>
  ) : renderActions.filter(Boolean).length > 0 ? (
    <div className="zDropDownActions" style={{ float: 'right' }}>
      {renderAction}
    </div>
  ) : null;
};
