import React from 'react';

interface SidebarCloseProps {
  children: any;
}

const SidebarClose: React.FC<SidebarCloseProps> = (props) => (
  <div className="sidebar__close">{props.children}</div>
);

export { SidebarClose };
