import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalProps } from 'reactstrap';

export interface ZModalProps extends ModalProps {
  isOpen?: boolean;
  Icon?: JSX.Element;
  modalTitle?: string | JSX.Element;
  toggle?: () => void;
}

const ZModal: React.FC<ZModalProps> = ({
  isOpen,
  children,
  Icon,
  modalTitle,
  toggle,
  ...modalProps
}) => {
  return (
    <Modal {...modalProps} isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        toggle={modalProps.backdrop !== 'static' ? toggle : undefined}
      >
        {Icon ? (
          <div className="d-flex align-items-center">
            <small className="mr-2">{Icon}</small> {modalTitle}
          </div>
        ) : (
          modalTitle
        )}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default ZModal;
