import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  QRCode,
  Button,
  Row,
  PageLoader,
  useToasts,
  Container,
} from '@zerintia/powerstone-ui';
import { getApi } from '../../../../services/api';

import style from './UserQr.module.css';
import { useUserSessionState } from '../../../../contexts/session';

interface UserQrProps {
  userId: string;
}

const UserQr: React.FC<UserQrProps> = ({ userId }) => {
  const { managementUri } = useUserSessionState();
  const { t } = useTranslation('core');
  const { showToast } = useToasts();
  const linkRef = React.useRef<HTMLAnchorElement>(null);
  const [apikey, setApikey] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [qrHref, setQrHref] = React.useState('');

  React.useEffect(() => {
    getApikey();
  }, []);

  const setQRUrlToLink = () => {
    const canvas = document.querySelector(
      '.HpQrcode > canvas'
    ) as HTMLCanvasElement;

    if (canvas) {
      setQrHref(canvas.toDataURL());
    }
  };

  const getApikey = async () => {
    try {
      const { data } = await getApi(managementUri).getUserApiKey(userId);
      const { hashedApikey } = data;

      setApikey(hashedApikey);
      setQRUrlToLink();
    } catch (err) {
      showToast('error', t('QR_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  const regenerateApikey = async () => {
    try {
      setLoading(true);
      await getApi(managementUri).regenerateApikey(userId);
      await getApikey();
    } catch (err) {
      showToast('error', t('QR_ERROR_GENERATION'));
    } finally {
      setLoading(false);
    }
  };

  const downloadApikey = () => {
    linkRef?.current?.click();
  };

  return (
    <Container>
      {loading && <PageLoader />}
      <Row className="justify-content-md-center">
        <div className="HpQrcode col-12 text-center p-3">
          {apikey ? (
            <QRCode
              className={style.qr}
              size={400}
              id="qrcode"
              value={apikey}
              onClick={downloadApikey}
            />
          ) : null}
        </div>
      </Row>
      <Row>
        <div className="ml-auto">
          <a ref={linkRef} download="QR_code.png" target="_self" href={qrHref}>
            <Button color="secondary" className="ml-3">
              {t('DOWNLOAD')}
            </Button>
          </a>
          <Button
            color="primary"
            type="button"
            className="ml-3"
            onClick={regenerateApikey}
          >
            {t('REGENERATE_QR')}
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default UserQr;
