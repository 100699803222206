const enTranslations = {
  TEST: 'Test',
  ACCEPT: 'Accept',
  CANCEL: 'Cancel',
  SWITCH_PROFILE: 'Switch profile',
  CHANGE_PASSWORD: 'Change password',
  CHANGE_PIN: 'Change pin',
  VIEW_QR: 'View QR',
  LOGOUT: 'Logout',
  LOADING: 'Loading',
  NEW_PASSWORD: 'New password',
  NEW_PIN: 'New pin',
  REPEAT_PASSWORD: 'Repeat password',
  REPEAT_PIN: 'Repeat pin',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  PASSWORD: 'Password',
  PASSWORD_RESTRICTIONS:
    'Password must have between {{min}} and {{max}} characters, no more than {{maxseq}} repeated characters',
  PIN_RESTRICTIONS:
    'Pin must have between {{min}} and {{max}} numeric characters, no more than 3 repeated characters',
  PIN_RESTRICTIONS_MATCH:
    'Pin must have {{max}} numeric characters, no more than 3 repeated characters',
  REQUIRED: 'Mandatory value',
  TEXT_MIN_LENGTH: 'Must be at least {{min}} characters in length.',
  TEXT_MAX_LENGTH: 'Must be at maximum {{max}} characters in length.',
  PIN_NOT_MATCH: 'Pins do not match',
  PIN: 'Pin',
  PIN_CAN_NOT_BE_THE_PREVIOUS:
    'The pin must be different from the previous one',
  INVALID_OLD_PIN: 'The current pin is invalid',
  ERROR_ON_PIN_REGENERATION: 'Error on changing pin',
  PIN_GENERATED: 'Your pin has been generated successfully.',
  PASSWORD_CAN_NOT_BE_THE_PREVIOUS:
    'The password must be different from the previous one',
  INVALID_OLD_PASSWORD: 'The current password is invalid',
  ERROR_ON_PASSWORD_REGENERATION: 'Error on changing password',
  PASSWORD_GENERATED: 'Your password has been generated successfully.',
  DOWNLOAD: 'Download',
  REGENERATE_QR: 'Regenerate QR',
  QR_ERROR: 'Error on QR retrieving',
  QR_ERROR_GENERATION: 'Error on QR generation',
  SELECT_SITES: 'Select sites',
  VARIOUS_SITES: 'Several plants',
  ACTIONS: 'Actions',
  PAGINATION_SHOWING: 'Showing',
  PAGINATION_PAGE_SIZE: 'Page Size',
  PAGINATION_PAGE: 'Page',
  PAGINATION_OF: 'of',
  PAGINATION_GO_TO: 'Go to',
  ERROR_ON_SET_ACTIVE_SITES: 'Error on site selection',
  PASSWORD_LENGTH_LESS: 'Length less than {{min}}',
  PASSWORD_LENGTH_MORE: 'Length less than {{max}}',
  PASSWORD_DIGITS: 'Must have at least {{dig}} digits',
  PASSWORD_UPP: 'Must have at least {{upp}} uppercase letters',
  PASSWORD_LOW: 'Must have at least {{low}} lowercase letters',
  PASSWORD_SPE: 'Must have at least {{spe}} special characters',
  PASSWORD_CONSECUTIVE: 'Must not have more than {{maxseq}} equal consecutive characters',
  SHOW_DISABLE_ITEMS: 'Show disabled items',
  PASSWORD_DIFF_OLD: "Current password can't match old password"
};

export default enTranslations;
