import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCssVariables } from '../../hooks/useCssVariables';

import style from './PageLoader.module.css';

interface PageLoaderProps {
  allowEverything?: boolean;
  allowTopbar?: boolean;
  allowMenu?: boolean;
  loadingText?: string | JSX.Element;
  delay?: number;
}

const PageLoader: React.FC<PageLoaderProps> = ({
  allowEverything,
  allowTopbar,
  allowMenu,
  loadingText,
  delay = 200,
}) => {
  const { t } = useTranslation('core');
  const [show, setShow] = React.useState(false);
  const [primary] = useCssVariables(['--primary']);

  React.useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  return show ? (
    <div className={`${allowEverything ? '' : `${style.pageLoaderBackground} ${allowTopbar ? style.behindTopbar : (allowMenu ? style.behindMenu: '')}`}`}>
      <div className={style.pageLoader}>
        <span>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="2rem"
            height="2rem"
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
            xmlSpace="preserve"
          >
            <g>
              <path
                fill="#e7e7e7"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
              />
              <path
                fill={primary}
                d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0C22.32,8.481,24.301,9.057,26.013,10.047z"
              />
            </g>
          </svg>
        </span>
        <span className={style.loaderText}>{loadingText || t('LOADING')}</span>
      </div>
    </div>
  ) : null;
};

export default PageLoader;
