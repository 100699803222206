import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

// Safely gets the browser document object,
// returns a simple mock for server rendering purposes
const getDocument = () =>
  typeof document === 'undefined'
    ? {
        querySelector() {
          return null;
        },
      }
    : document;

interface OuterClickProps {
  onClickOutside?: Function;
  excludedElements?: any[];
  active?: boolean;
  children?: React.ReactNode;
}
/*
    Calls an EventHandler when User clicks outside of the child element
*/
class OuterClick extends React.Component<OuterClickProps> {
  public rootElement: any;
  public elementRef: any;

  componentDidMount() {
    this.rootElement = getDocument().querySelector('body');

    if (this.rootElement) {
      this.rootElement.addEventListener('click', this.handleDocumentClick);
      this.rootElement.addEventListener('touchstart', this.handleDocumentClick);
    }
  }

  componentWillUnmount() {
    if (this.rootElement) {
      this.rootElement.removeEventListener('click', this.handleDocumentClick);
      this.rootElement.removeEventListener(
        'touchstart',
        this.handleDocumentClick
      );
    }
  }

  assignRef(elementRef: any) {
    this.elementRef = elementRef;
  }

  handleDocumentClick = (evt: any) => {
    if (this.props.active) {
      const domElement = ReactDOM.findDOMNode(this.elementRef);

      const isExcluded = _.some(
        this.props.excludedElements || [],
        (element) =>
          element && ReactDOM.findDOMNode(element)?.contains(evt.target)
      );

      if (!isExcluded && !domElement?.contains(evt.target)) {
        this.props.onClickOutside && this.props.onClickOutside(evt);
      }
    }
  };

  render() {
    const onlyChild: any = React.Children.only<any>(this.props.children);

    const updatedChild = React.isValidElement(onlyChild)
      ? React.cloneElement<any>(onlyChild, {
          ref: this.assignRef.bind(this),
        })
      : onlyChild;

    return updatedChild;
  }
}

export { OuterClick };
