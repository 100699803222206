import * as React from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from '@zerintia/powerstone-ui';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';

import style from './NavbarAppsSelector.module.css';
import {
  ActionLogo,
  AfterSalesLogo,
  LogisticLogo,
  ManagementLogo,
  ManufacturingLogo,
  ProcessLogo,
  RemoteLogo,
  RulesEngineLogo,
  SafetyLogo,
} from './appsLogos';

interface ZerintiaAppsProps {
  href: string;
  name?: string;
}

type ZerintiaApps = {
  '4Action'?: ZerintiaAppsProps;
  '4AfterSales'?: ZerintiaAppsProps;
  '4Logistic'?: ZerintiaAppsProps;
  '4Management'?: ZerintiaAppsProps;
  '4Manufacturing'?: ZerintiaAppsProps;
  '4Process'?: ZerintiaAppsProps;
  '4Remote'?: ZerintiaAppsProps;
  RulesEngine?: ZerintiaAppsProps;
  '4Safety'?: ZerintiaAppsProps;
};

interface NavbarAppsSelectorProps {
  appLinks: ZerintiaApps;
  isLastNavbarElement?: boolean;
}

const appLogos: Record<keyof ZerintiaApps, JSX.Element> = {
  '4Action': <ActionLogo />,
  '4AfterSales': <AfterSalesLogo />,
  '4Logistic': <LogisticLogo />,
  '4Management': <ManagementLogo />,
  '4Manufacturing': <ManufacturingLogo />,
  '4Process': <ProcessLogo />,
  '4Remote': <RemoteLogo />,
  RulesEngine: <RulesEngineLogo />,
  '4Safety': <SafetyLogo />,
};

const NavbarAppsSelector: React.FC<NavbarAppsSelectorProps> = ({
  appLinks,
  isLastNavbarElement = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      inNavbar={isLastNavbarElement}
    >
      <DropdownToggle
        tag="button"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        className={style.toggle}
      >
        <FontAwesomeIcon icon={'th'} />
      </DropdownToggle>
      <DropdownMenu right>
        <div className={style.menu}>
          {Object.keys(appLinks).map((appName) => {
            return (
              <a
                key={appName}
                className={style.logoContainer}
                href={appLinks[appName as keyof ZerintiaApps]?.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {React.cloneElement(appLogos[appName as keyof ZerintiaApps])}
                <p>
                  {appLinks[appName as keyof ZerintiaApps]?.name || appName}
                </p>
              </a>
            );
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NavbarAppsSelector;
