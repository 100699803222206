import React, { useState, useCallback } from 'react';
import FileDrop from 'react-dropzone';

import { FontAwesomeIcon } from '@zerintia/powerstone-icons';

const readURL = (file: any) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res(e?.target?.result);
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
};

export interface ImageInputProps {
  id?: string;
  image?: any;
  label?: string;
  invalid?: boolean;
  placeholderDrag?: boolean;
  placeholderTitle?: string;
  placeholderBody?: string;
  onChange?: Function;
}

export const ImageInput: React.FC<ImageInputProps> = ({
  image,
  placeholderDrag,
  id,
  placeholderTitle = 'Upload Your files',
  placeholderBody = 'Drag a file here or browse for a file to upload.',
  onChange,
  invalid,
}) => {
  const [currentimage, setCurrentImage] = useState(() => image || null);

  const handleImageChange = useCallback(
    async (files: any[]) => {
      try {
        const src = await readURL(files[0]);
        setCurrentImage(src);
        onChange && onChange(files[0]);
      } catch (err) {
        console.error(err);
      }
    },
    [onChange]
  );

  const handleDeleteImage = useCallback(
    (event: any) => {
      event.stopPropagation();
      setCurrentImage(null);
      onChange && onChange(null);
    },
    [onChange]
  );

  return (
    <React.Fragment>
      <section
        className={`text-center hover-upload ${invalid ? `input-invalid` : ''}`}
      >
        <FileDrop onDrop={handleImageChange}>
          {({ getRootProps, getInputProps }: any) => (
            <div {...getRootProps()} className="dropzoneClass" id={id}>
              {currentimage ? (
                <div>
                  <img src={currentimage} className="full-width" />
                  <div className="delete-img-button">
                    <FontAwesomeIcon
                      icon={'times'}
                      className="fa-2x"
                      onClick={handleDeleteImage}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon
                    icon={'upload'}
                    className="fa-fw fa-3x mb-3"
                  />
                  <h5 className="mt-0">{placeholderTitle}</h5>
                  <div>{placeholderDrag || <p>{placeholderBody}</p>}</div>
                </div>
              )}
              <input
                {...getInputProps()}
                accept="image/*"
                aria-label="imageUpload"
              />
            </div>
          )}
        </FileDrop>
      </section>
    </React.Fragment>
  );
};
