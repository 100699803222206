import React from 'react';

import { OuterClick } from '../OuterClick';
import { withPageConfig } from '../Layout';
import { SidebarContent } from './SidebarContent';

interface SidebarProps {
  slim?: boolean;
  collapsed?: boolean;
  animationsDisabled?: boolean;
  pageConfig: Record<string, any>;
}

const Sidebar: React.FC<SidebarProps> = (props) => (
  <OuterClick
    active={
      !props.pageConfig.sidebarCollapsed &&
      (props.pageConfig.screenSize === 'xs' ||
        props.pageConfig.screenSize === 'sm' ||
        props.pageConfig.screenSize === 'md')
    }
    onClickOutside={() => props.pageConfig.toggleSidebar()}
  >
    <SidebarContent {...props} />
  </OuterClick>
);

const cfgSidebar = withPageConfig(Sidebar);

export { cfgSidebar as Sidebar };
