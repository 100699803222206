import * as React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from '@zerintia/powerstone-ui';

import style from './style.module.css';
import { useTranslation } from 'react-i18next';

interface TablePaginationProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (pageIndex: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageCount: number;
  pageSize: number;
}

const TablePagination: React.FC<TablePaginationProps> = ({
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  setPageSize,
  pageIndex,
  pageCount,
  pageSize,
}) => {
  const { t } = useTranslation('core');

  return (
    <div className="d-flex justify-content-between align-items-baseline">
      <UncontrolledButtonDropdown className="d-block" size="sm">
        <DropdownToggle
          size="sm"
          color="link"
          className="text-decoration-none text-muted"
          caret
        >
          {`${t('PAGINATION_SHOWING')} ${pageSize}`}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{t('PAGINATION_PAGE_SIZE')}</DropdownItem>
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <DropdownItem
              onClick={() => setPageSize(pageSize)}
              key={`pageSize-${pageSize}`}
            >
              {pageSize}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <div className="text-muted">
        <small>
          {t('PAGINATION_PAGE')} {pageIndex + 1} {t('PAGINATION_OF')}{' '}
          {pageCount || 0}
        </small>
        <small className="mx-1">|</small>
        <div className="d-inline-flex align-items-center">
          <small>{t('PAGINATION_GO_TO')}</small>
          <Input
            className={style.pageSelector}
            bsSize="sm"
            type="number"
            defaultValue={pageIndex + 1}
            min="1"
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </div>
      </div>
      <Pagination aria-label="tablePagination">
        <PaginationItem disabled={!canPreviousPage}>
          <PaginationLink first onClick={() => gotoPage(0)} />
        </PaginationItem>
        <PaginationItem disabled={!canPreviousPage}>
          <PaginationLink previous onClick={() => previousPage()} />
        </PaginationItem>
        <PaginationItem disabled={!canNextPage}>
          <PaginationLink next onClick={() => nextPage()} />
        </PaginationItem>
        <PaginationItem disabled={!canNextPage}>
          <PaginationLink last onClick={() => gotoPage(pageCount - 1)} />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default TablePagination;
