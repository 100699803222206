import * as React from 'react';
import classNames from 'classnames';
import { Card, CardBody, Button, FormGroup, CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';

import '../../../styles/components/theme-selector.scss';
import { usePageConfig } from '../Layout';
import { useTheme } from './useTheme';

interface ThemeSelectorProps {
  styleOptions?: any[];
  colorOptions?: any[];
}

const ThemeSelector: React.FC<ThemeSelectorProps> = ({
  styleOptions = [
    { name: 'Light', value: 'light' },
    { name: 'Dark', value: 'dark' },
    { name: 'Color', value: 'color' },
  ],
  colorOptions = [
    { name: 'Primary', value: 'primary' },
    { name: 'Success', value: 'success' },
    { name: 'Info', value: 'info' },
    { name: 'Danger', value: 'danger' },
    { name: 'Warning', value: 'warning' },
    { name: 'Indigo', value: 'indigo' },
    { name: 'Purple', value: 'purple' },
    { name: 'Pink', value: 'pink' },
    { name: 'Yellow', value: 'yellow' },
  ],
}) => {
  const { style, color, onChangeTheme } = useTheme();
  const { sidebarHidden, navbarHidden } = usePageConfig();
  const [theme, setTheme] = React.useState({
    isActive: false,
    initialStyle: style,
    initialColor: color,
  });

  const rootClass = classNames('theme-config', {
    'theme-config--active': theme.isActive,
  });

  return (
    <div className={rootClass}>
      <Button
        color="primary"
        className="theme-config__trigger"
        onClick={() => {
          setTheme((prev) => ({ ...prev, isActive: !prev.isActive }));
        }}
      >
        <FontAwesomeIcon icon={'paintbrush'} />
      </Button>
      <Card className="theme-config__body">
        <CardBody>
          <h6 className="text-center mb-3">Configurator</h6>

          <FormGroup>
            <span className="h6 small mb-2 d-block">Nav Color</span>
            {colorOptions.map((option, index) => (
              <CustomInput
                key={index}
                type="radio"
                name="sidebarColor"
                id={`sidebarStyle--${option.value}`}
                value={option.value}
                checked={color === option.value}
                onChange={(ev) => {
                  if (ev.target.checked) {
                    onChangeTheme({
                      color: option.value,
                    });
                  }
                }}
                label={
                  <span className="d-flex align-items-center">
                    {option.name}
                    <i
                      className={`fa fa-circle ml-auto text-${option.value}`}
                    />
                  </span>
                }
              />
            ))}
          </FormGroup>
          <FormGroup>
            <span className="h6 small mb-2 d-block">Nav Style</span>
            {styleOptions.map((option, index) => (
              <CustomInput
                key={index}
                type="radio"
                name="sidebarStyle"
                id={`sidebarStyle--${option.value}`}
                value={option.value}
                disabled={sidebarHidden && navbarHidden}
                checked={style === option.value}
                onChange={(ev) => {
                  if (ev.target.checked) {
                    onChangeTheme({
                      style: option.value,
                    });
                  }
                }}
                label={option.name}
              />
            ))}
          </FormGroup>
          <FormGroup className="mb-0">
            <Button
              color="secondary"
              outline
              className="d-block w-100"
              onClick={() => {
                onChangeTheme({
                  color: theme.initialColor,
                  style: theme.initialStyle,
                });
              }}
            >
              Reset Options
            </Button>
          </FormGroup>
        </CardBody>
      </Card>
    </div>
  );
};

export { ThemeSelector };
