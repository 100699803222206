import React, { HTMLProps } from 'react';
import PropTypes from 'prop-types';
import { ImageProps } from './index';

interface ThumbnailsPoprs extends HTMLProps<HTMLDivElement> {
  images: ImageProps[];
  activeIndex: number;
  setActiveIndex: Function;
}
const Thumbnails = ({
  images,
  activeIndex,
  setActiveIndex,
  ...rest
}: ThumbnailsPoprs) => {
  return (
    <div
      {...rest}
      style={{ position: 'relative', touchAction: 'span-y', ...rest.style }}
    >
      <div
        className="py-1 h-100"
        style={{
          overflow: 'hidden',
        }}
      >
        <nav
          id="thumbnails-id"
          className="h-100"
          style={{
            transition: 'all 450ms ease-out 0s',
            cursor: 'pointer',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            transform: `translate3d(-${activeIndex * 25}px, 0px, 0px)`,
          }}
        >
          {images.map((image, index) => (
            <button
              key={index}
              className="h-100"
              style={{
                margin: 2,
                outline: 'none',
                display: 'inline-block',
                transition: 'border .3s ease-out',
                width: '100px',
                background: 'transparent',
                padding: 0,
                border: 'none',
              }}
              onClick={() => setActiveIndex(index)}
            >
              <span
                className="h-100"
                style={{
                  display: 'block',
                  position: 'relative',
                  opacity: index === activeIndex ? '1' : '.6',
                }}
              >
                <img
                  alt={image.alt}
                  src={image.url}
                  style={{
                    maxWidth: '100%',
                    objectFit: 'cover',
                    width: 'auto',
                    lineHeight: 0,
                    verticalAlign: 'middle',
                  }}
                  className="rounded-sm h-100"
                />
              </span>
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

Thumbnails.propTypes = {
  images: PropTypes.array,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
};

export default Thumbnails;
