import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomInput, Label } from '@zerintia/powerstone-ui';

interface TableSwitchShowDeleted {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableSwitchShowDeleted: React.FC<TableSwitchShowDeleted> = ({
  setShow,
  show = false,
}: TableSwitchShowDeleted) => {
  const { t } = useTranslation('core');

  const toggle = () => {
    setShow((prevState) => !prevState);
  };
  return (
    <div className="w-100 d-flex flex-row justify-content-end">
      <Label className="mr-2 mb-0">{t('SHOW_DISABLE_ITEMS')}</Label>
      <CustomInput type="switch" id={'show'} checked={show} onChange={toggle} />
    </div>
  );
};

export default TableSwitchShowDeleted;
