import React from 'react';
import classNames from 'classnames';

const LayoutSidebar = (props: any) => {
  const sidebarClass = classNames('layout__sidebar', {
    'layout__sidebar--slim': props.sidebarSlim,
    'layout__sidebar--collapsed': props.sidebarCollapsed,
  });

  return <div className={sidebarClass}>{props.children}</div>;
};

LayoutSidebar.layoutPartName = 'sidebar';

export { LayoutSidebar };
