import React from 'react';

import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';

import locales from 'date-fns/locale';

export interface TimeInputProps extends ReactDatePickerProps {
  value: any;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  timeIntervals?: number;
  timeFormat?: string;
  dateFormat?: string;
  onChange: (date: Date) => any;
}

export const TimeInput: React.FC<TimeInputProps> = ({
  value,
  placeholder,
  disabled,
  invalid,
  timeIntervals = 15,
  timeFormat = "HH:mm:ss",
  dateFormat = "HH:mm:ss",
  showTimeSelect = true,
  showTimeSelectOnly = true,
  timeCaption = "TIME",
  ...rest
}) => {

  Object.entries(locales).forEach(([code, locale]) => {
    registerLocale(code, locale);
  });

  return (
    <>
      <DatePicker
        {...rest}
        className={`${invalid ? 'input-invalid form-control' : 'form-control'}${rest?.className || ''}`}
        placeholderText={placeholder || 'Click to select a date'}
        selected={value}
        disabled={disabled}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
      />
    </>
  );
};
