import React from 'react';

import { Navbar } from '../Navbar';
import { Nav } from '../Nav';

import { Breadcrumbs } from './Breadcrumbs';

interface DefaultNavbarProps {
  home?: string;
  children?: React.ReactNode;
}

export const DefaultNavbar: React.FC<DefaultNavbarProps> = ({
  home,
  children,
}) => (
  <Navbar light expand="xs" fluid>
    <Breadcrumbs home={home} />
    <Nav navbar className="ml-auto">
      {children}
    </Nav>
  </Navbar>
);
