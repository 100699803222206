import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';

import { SidebarTrigger } from '../SidebarTrigger';
import {
  Sidebar,
  SidebarClose,
  SidebarHideSlim,
  SidebarMobileFluid,
  SidebarSection,
} from '../Sidebar';
import { SidebarMiddleNav } from '../SidebarMiddleNav';
import { LogoThemed } from '../LogoThemed';

interface DefaultSidebarProps {
  resources: Resource[];
  baseHref?: string;
}

export const DefaultSidebar: React.FC<DefaultSidebarProps> = ({
  resources,
  baseHref,
}) => {
  return (
    <Sidebar>
      {/* START SIDEBAR-OVERLAY: Close (x) */}
      <SidebarClose>
        <SidebarTrigger>
          <FontAwesomeIcon icon={'times-circle'} />
        </SidebarTrigger>
      </SidebarClose>
      {/* START SIDEBAR-OVERLAY: Close (x) */}

      {/* START SIDEBAR: Only for Desktop */}
      <SidebarHideSlim>
        <SidebarSection>
          <Link to="/" className="sidebar__brand">
            <LogoThemed height="45" checkBackground baseHref={baseHref} />
          </Link>
        </SidebarSection>
      </SidebarHideSlim>
      {/* END SIDEBAR: Only for Desktop */}

      {/* START SIDEBAR: Only for Mobile */}
      <SidebarMobileFluid>
        <SidebarSection fluid cover>
          {/* SIDEBAR: Menu */}
          <SidebarMiddleNav resources={resources} />
        </SidebarSection>
      </SidebarMobileFluid>
      {/* END SIDEBAR: Only for Mobile */}
    </Sidebar>
  );
};
