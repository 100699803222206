import * as React from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  HeaderSection,
} from '@zerintia/powerstone-ui';

interface ZFormContainerProps {
  title: string;
  subTitle?: string;
  cardClassName?: string;
  cardBodyClassName?: string;
  cardHeaderClassName?: string;
  children?: React.ReactNode;
}

const ZFormContainer: React.FC<ZFormContainerProps> = ({
  title,
  subTitle,
  cardClassName,
  cardBodyClassName,
  cardHeaderClassName,
  children,
}) => {
  return (
    <Container fluid>
      <HeaderSection title={title} subTitle={subTitle} className={cardHeaderClassName} />
      <Row className="justify-content-lg-center">
        <Col lg={12}>
          <Card className={cardClassName}>
            <CardBody className={cardBodyClassName}>{children}</CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ZFormContainer;
