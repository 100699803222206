import React from 'react';

import { Consumer } from './ThemeContext';

interface ThemeClassProps {
  color?: string;
  style?: string;
  children?: any;
}

const ThemeClass: React.FC<ThemeClassProps> = ({ children, color, style }) => {
  const layoutThemeClass = `layout--theme--${style}--${color}`;

  return children(layoutThemeClass);
};

const ContextThemeClass = (otherProps: any) => (
  <Consumer>
    {(themeState: any) => <ThemeClass {...{ ...themeState, ...otherProps }} />}
  </Consumer>
);

export { ContextThemeClass as ThemeClass };
