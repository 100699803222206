import * as React from 'react';
import { Navbar, Nav } from '@zerintia/powerstone-ui';

const TableNabvar: React.FC<any> = ({ children }) => {
  return (
    <Navbar light expand="md" className="p-0">
      <Nav aria-label="tableControls" navbar className='w-100 d-flex justify-content-between'>
        {children}
      </Nav>
    </Navbar>
  );
};

export default TableNabvar;
