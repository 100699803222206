import React from 'react';
import classNames from 'classnames';
import * as uuid from 'uuid';

import { MenuContext } from './MenuContext';
import { SidebarMenuItemLink } from './SidebarMenuItemLink';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';

import style from './SidebarMenuItem.module.css';

interface SidebarMenuItemProps {
  addEntry?: Function;
  updateEntry?: Function;
  removeEntry?: Function;
  entries?: Record<string, any>;
  parentId?: string;
  isSubNode?: boolean;
  currentUrl?: string;
  slim?: boolean;
  icon?: JSX.Element;
  title?: JSX.Element | string;
  to?: string;
  href?: string;
  exact?: boolean;
  noCaret?: boolean;
  onClick?: Function;
  children?: React.ReactNode;
}
/**
 * The main menu entry component
 */
export class SidebarMenuItem extends React.Component<SidebarMenuItemProps> {
  static defaultProps: Partial<SidebarMenuItemProps> = {
    exact: true,
  };

  public id: string = uuid.v4();

  constructor(props: SidebarMenuItemProps) {
    super(props);
  }

  componentDidMount() {
    const entry = {
      id: this.id,
      parentId: this.props.parentId,
      exact: !!this.props.exact,
      url: '',
    };

    if (this.props.to) {
      entry.url = this.props.to;
    }

    this.props.addEntry && this.props.addEntry(entry);
  }

  componentWillUnmount() {
    this.props.removeEntry && this.props.removeEntry(this.id);
  }

  getEntry() {
    if (this.props.entries) {
      return this.props.entries[this.id];
    }
  }

  toggleNode() {
    const entry = this.getEntry();

    this.props.updateEntry &&
      this.props.updateEntry(this.id, { open: !entry.open });
  }

  renderChevronIcon(entry: any) {
    if (this.props.slim && !this.props.parentId) {
      return null;
    }

    if (this.props.children) {
      return (
        <FontAwesomeIcon
          icon={'chevron-left'}
          className={classNames(style.chevronIcon, {
            [style.open]: entry && entry.open,
          })}
        />
      );
    }

    return null;
  }

  render() {
    const entry = this.getEntry();
    const classBase = this.props.isSubNode ? 'sidebar-submenu' : 'sidebar-menu';
    const itemClass = classNames(`${classBase}__entry`, {
      [`${classBase}__entry--nested`]: !!this.props.children,
      open: entry && entry.open,
      active: entry && entry.active,
    });

    return (
      <li
        className={classNames(itemClass, {
          'sidebar-menu__entry--no-caret': this.props.noCaret,
        })}
      >
        <SidebarMenuItemLink
          to={this.props.to}
          href={this.props.href}
          onClick={this.props.onClick}
          onToggle={this.toggleNode.bind(this)}
          classBase={classBase}
        >
          {this.props.icon &&
            React.cloneElement(this.props.icon, {
              className: classNames(this.props.icon.props.className),
            })}
          {typeof this.props.title === 'string' ? (
            <span>{this.props.title}</span>
          ) : (
            this.props.title
          )}
          {this.renderChevronIcon(entry)}
        </SidebarMenuItemLink>
        {this.props.children && (
          <ul className="sidebar-submenu">
            {React.Children.map(this.props.children, (child: any) => (
              <MenuContext.Consumer>
                {(ctx) =>
                  React.cloneElement(child, {
                    isSubNode: true,
                    parentId: this.id,
                    currentUrl: this.props.currentUrl,
                    slim: this.props.slim,
                    ...ctx,
                  })
                }
              </MenuContext.Consumer>
            ))}
          </ul>
        )}
      </li>
    );
  }
}
