import * as React from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@zerintia/powerstone-ui';

interface TableColumnSelector {
  allColumns: any[];
  columnSelectorLabel?: string;
}

const TableColumnSelector: React.FC<TableColumnSelector> = ({
  allColumns,
  columnSelectorLabel = 'Filters',
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="a"
        className="nav-link"
        style={{ cursor: 'pointer' }}
        caret
      >
        {columnSelectorLabel}
      </DropdownToggle>
      <DropdownMenu>
        {allColumns.map((column) => {
          return (
            <DropdownItem key={column.id} header>
              <input
                type="checkbox"
                onClick={() => setDropdownOpen(false)}
                {...column.getToggleHiddenProps()}
              />{' '}
              {column.id}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default TableColumnSelector;
