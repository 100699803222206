import React from 'react';
import classNames from 'classnames';

import { Nav as BsNav, NavProps as BSNavProps } from 'reactstrap';

interface NavProps extends BSNavProps {
  accent?: boolean;
}

const Nav: React.FC<NavProps> = ({
  accent = false,
  className,
  ...otherProps
}) => {
  return (
    <BsNav
      className={classNames(className, 'nav', { 'nav-accent': accent })}
      {...otherProps}
    />
  );
};

export { Nav };
