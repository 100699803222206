import React from 'react';

const LayoutNavbar = (props: any) => {
  const navbar = React.Children.only(props.children);

  return (
    <div className="layout__navbar">
      {React.cloneElement(navbar, { fixed: null })}
    </div>
  );
};

LayoutNavbar.layoutPartName = 'navbar';

export { LayoutNavbar };
