import * as React from 'react';
import ZModal, { ZModalProps } from './ZModal';

export interface ZModalActionsProps {
  openModal: (modalProps: ZModalProps & { component?: JSX.Element }) => void;
  closeModal: () => void;
}

export function withZModal<P>(
  WrappedComponent: React.ComponentType<P & ZModalActionsProps>
) {
  const ComponentWithZModal: React.FC<P & ZModalActionsProps> = (props) => {
    const [modal, setModal] = React.useState(false);
    const [modalProps, setModalProps] = React.useState<
      ZModalProps & { component?: JSX.Element }
    >();

    const openModal = (
      modalProps: ZModalProps & { component?: JSX.Element } = {}
    ) => {
      const { isOpen, togle, ...rest } = modalProps;
      setModalProps(rest);
      setModal(true);
    };

    const closeModal = () => {
      setModal(false);
    };

    const toggle = () => {
      setModal(!modal);
    };

    return (
      <>
        <WrappedComponent
          {...props}
          closeModal={closeModal}
          openModal={openModal}
        />
        <ZModal {...modalProps} isOpen={modal} toggle={toggle}>
          {modalProps?.component}
        </ZModal>
      </>
    );
  };

  return ComponentWithZModal as React.FC<Omit<P, keyof ZModalActionsProps>>;
}
