import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NavItem } from 'reactstrap';
import { Nav } from '../Nav';
import { SidebarTrigger } from '../SidebarTrigger';
import { FontAwesomeIcon } from '@zerintia/powerstone-icons';
import { usePageConfig } from '../Layout/usePageConfig';

import { LogoThemed } from '../LogoThemed';

interface BreadcrumbsProps {
  home?: string;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ home = '/' }) => {
  const { t } = useTranslation();
  const { crumbs } = usePageConfig();

  return (
    <Nav navbar className="align-items-center">
      <NavItem className="mr-3">
        <SidebarTrigger />
      </NavItem>
      <NavItem className="navbar-brand d-lg-none">
        <Link to="/">
          <LogoThemed height="40" />
        </Link>
      </NavItem>
      <NavItem className="d-none d-md-block">
        <span className="navbar-text">
          <Link to={home}>
            <FontAwesomeIcon icon={'home'} />
          </Link>
        </span>
        {crumbs?.map(({ path, name, state }) => {
          return (
            <span key={path}>
              <span className="navbar-text px-2">
                <FontAwesomeIcon icon={'angle-right'} />
              </span>
              <span className="navbar-text">
                <Link
                  to={{
                    pathname: path.split('?')[0],
                    search: path.split('?')[1] ? `?${path.split('?')[1]}` : '',
                    state,
                  }}
                >
                  {t(name)}
                </Link>
              </span>
            </span>
          );
        })}
      </NavItem>
    </Nav>
  );
};
