import * as React from 'react';
import { Button } from '@zerintia/powerstone-ui';

interface SubmitSectionProps {
  submitLabel: string | JSX.Element;
  cancelLabel: string | JSX.Element;
  onCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SubmitSection: React.FC<SubmitSectionProps> = ({
  submitLabel,
  cancelLabel,
  onCancel = () => {},
}) => {
  return (
    <section className="d-flex justify-content-end">
      <Button color="primary" type="submit" className="mr-2">
        {submitLabel}
      </Button>
      <Button type="button" onClick={onCancel}>
        {cancelLabel}
      </Button>
    </section>
  );
};

export default SubmitSection;
