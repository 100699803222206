const esTranslations = {
  TEST: 'Prueba',
  ACCEPT: 'Aceptar',
  CANCEL: 'Cancelar',
  SWITCH_PROFILE: 'Cambiar perfil',
  CHANGE_PASSWORD: 'Cambiar contraseña',
  CHANGE_PIN: 'Cambiar pin',
  VIEW_QR: 'Ver QR',
  LOGOUT: 'Salir',
  LOADING: 'Cargando',
  NEW_PASSWORD: 'Nueva contraseña',
  NEW_PIN: 'Nuevo pin',
  REPEAT_PASSWORD: 'Repetir contraseña',
  REPEAT_PIN: 'Repetir pin',
  PASSWORD_NOT_MATCH: 'Las contraseñas no coinciden',
  PASSWORD: 'Contraseña',
  PASSWORD_RESTRICTIONS:
    'La contraseña debe tener entre {{min}} y {{max}} caracteres, no más de {{maxseq}} caracteres repetidos',
  PIN_RESTRICTIONS:
    'El PIN debe tener entre {{min}} y {{max}} caracteres numéricos, no más de 3 caracteres repetidos',
  PIN_RESTRICTIONS_MATCH:
    'El PIN debe tener {{max}} caracteres numéricos, no más de 3 caracteres repetidos',
  REQUIRED: 'Campo obligatorio',
  TEXT_MIN_LENGTH: 'Debe tener al menos {{min}} caracteres',
  TEXT_MAX_LENGTH: 'Debe tener como máximo {{max}} caracteres',
  PIN_NOT_MATCH: 'Los pines no coinciden',
  PIN: 'Pin',
  PIN_CAN_NOT_BE_THE_PREVIOUS: 'El pin debe ser diferente al anterior',
  INVALID_OLD_PIN: 'El pin actual es inválido',
  ERROR_ON_PIN_REGENERATION: 'Error en la generación del pin',
  PIN_GENERATED: 'El pin ha sido generado con éxito',
  PASSWORD_CAN_NOT_BE_THE_PREVIOUS:
    'La contraseña debe ser diferente a la anterior',
  INVALID_OLD_PASSWORD: 'La contraseña actual es inválida',
  ERROR_ON_PASSWORD_REGENERATION: 'Error en la generación de la contraseña',
  PASSWORD_GENERATED: 'La contraseña ha sido generada con éxito.',
  DOWNLOAD: 'Descargar',
  REGENERATE_QR: 'Regenerar QR',
  QR_ERROR: 'Error al recuperar QR',
  QR_ERROR_GENERATION: 'Error al generar QR',
  SELECT_SITES: 'Seleccionar plantas',
  VARIOUS_SITES: 'Varias plantas',
  ACTIONS: 'Acciones',
  PAGINATION_SHOWING: 'Mostrando',
  PAGINATION_PAGE_SIZE: 'Tamaño de página',
  PAGINATION_PAGE: 'Página',
  PAGINATION_OF: 'de',
  PAGINATION_GO_TO: 'Ir a',
  ERROR_ON_SET_ACTIVE_SITES: 'Error en la selección de planta',
  PASSWORD_LENGTH_LESS: 'Longitud menor de {{min}}',
  PASSWORD_LENGTH_MORE: 'Longitud mayor de {{max}}',
  PASSWORD_DIGITS: 'Debe contener al menos {{dig}} digitos',
  PASSWORD_UPP: 'Debe contener al menos {{upp}} mayúsculas',
  PASSWORD_LOW: 'Debe contener al menos {{low}} minúsculas',
  PASSWORD_SPE: 'Debe contener al menos {{spe}} carácteres especiales',
  PASSWORD_CONSECUTIVE: 'No debe tener más de {{maxseq}} caracteres iguales consecutivos',
  SHOW_DELETED_ITEMS: 'Mostrar elementos deshabilidatos',
  PASSWORD_DIFF_OLD: "La contraseña actual no puede coincidir con la contraseña anterior"
};

export default esTranslations;
