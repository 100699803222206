import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash-es';

import { LayoutContent } from './LayoutContent';
import { LayoutNavbar } from './LayoutNavbar';
import { LayoutSidebar } from './LayoutSidebar';
import { PageConfigContext } from './PageConfigContext';
import { ThemeClass } from '../Theme/ThemeClass';

import config from '../../../configurations';

const findChildByType = (children: any, targetType: any) => {
  let result;

  React.Children.forEach(children, (child) => {
    if (child.type.layoutPartName === targetType.layoutPartName) {
      result = child;
    }
  });

  return result;
};
const findChildrenByType = (children: any, targetType: any) => {
  return _.filter(
    React.Children.toArray(children),
    (child: any) => child.type.layoutPartName === targetType.layoutPartName
  );
};

const responsiveBreakpoints = {
  xs: { max: 575.8 },
  sm: { min: 576, max: 767.8 },
  md: { min: 768, max: 991.8 },
  lg: { min: 992, max: 1199.8 },
  xl: { min: 1200 },
};

type PathParamsType = {
  pathname: string;
};

type LayoutProps = RouteComponentProps<PathParamsType> & {
  pageTilte?: string;
  sidebarSlim?: boolean;
  location?: Record<string, any>;
  favIcons?: any[];
  children?: React.ReactNode;
};

interface LayoutState {
  sidebarHidden: boolean;
  navbarHidden: boolean;
  footerHidden: boolean;
  sidebarCollapsed: boolean;
  screenSize: string;
  animationsDisabled: boolean;
  pageTitle: any | null;
  pageDescription: string;
  pageKeywords: string;
  crumbs?: Array<{ name: string; path: string; state?: Record<string, any> }>;
}

class Layout extends React.Component<LayoutProps, LayoutState> {
  public lastLgSidebarCollapsed: boolean = false;
  public containerRef: any;
  public bodyElement: any;
  public documentElement: any;

  constructor(props: LayoutProps) {
    super(props);

    this.state = {
      sidebarHidden: false,
      navbarHidden: false,
      footerHidden: false,
      sidebarCollapsed: false,
      screenSize: '',
      animationsDisabled: true,

      pageTitle: null,
      pageDescription: config.siteDescription,
      pageKeywords: config.siteKeywords,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    // Determine the current window size
    // and set it up in the context state
    const layoutAdjuster = () => {
      const { screenSize } = this.state;
      let currentScreenSize;

      _.forOwn(responsiveBreakpoints, (value: any, key: any) => {
        const queryParts = [
          `${_.isUndefined(value.min) ? '' : `(min-width: ${value.min}px)`}`,
          `${_.isUndefined(value.max) ? '' : `(max-width: ${value.max}px)`}`,
        ];
        const query = _.compact(queryParts).join(' and ');

        if (window.matchMedia(query).matches) {
          currentScreenSize = key;
        }
      });

      if (screenSize !== currentScreenSize) {
        this.setState({ screenSize: currentScreenSize } as any);
        this.updateLayoutOnScreenSize(currentScreenSize);
      }
    };

    // Add window initialization
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        setTimeout(layoutAdjuster.bind(this), 0);
      });

      layoutAdjuster();

      window.requestAnimationFrame(() => {
        this.setState({ animationsDisabled: false });
      });
    }
    // Add document initialization
    if (typeof document !== 'undefined') {
      this.bodyElement = document.body;
      this.documentElement = document.documentElement;
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Prevent content scrolling in overlay mode
    if (
      this.bodyElement &&
      this.documentElement &&
      (this.state.screenSize === 'xs' ||
        this.state.screenSize === 'sm' ||
        this.state.screenSize === 'md')
    ) {
      if (prevState.sidebarCollapsed !== this.state.sidebarCollapsed) {
        // Most of the devices
        const styleUpdate = this.state.sidebarCollapsed
          ? {
              overflowY: 'auto',
              touchAction: 'auto',
            }
          : {
              overflowY: 'hidden',
              touchAction: 'none',
            };
        Object.assign(this.bodyElement.style, styleUpdate);
        Object.assign(this.documentElement.style, styleUpdate);
      }
    }

    // After location change
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // Scroll to top
      if (this.bodyElement && this.documentElement) {
        this.documentElement.scrollTop = this.bodyElement.scrollTop = 0;
      }

      // Hide the sidebar when in overlay mode
      if (
        !this.state.sidebarCollapsed &&
        (this.state.screenSize === 'xs' ||
          this.state.screenSize === 'sm' ||
          this.state.screenSize === 'md')
      ) {
        // Add some time to prevent jank while the dom is updating
        setTimeout(() => {
          this.setState({ sidebarCollapsed: true });
        }, 100);
      }
    }

    // Update positions of STICKY navbars
    this.updateNavbarsPositions();
  }

  updateLayoutOnScreenSize(screenSize?: string) {
    if (screenSize === 'md' || screenSize === 'sm' || screenSize === 'xs') {
      // Save for recovering to lg later
      this.lastLgSidebarCollapsed = this.state.sidebarCollapsed;
      this.setState({ sidebarCollapsed: true });
    } else {
      this.setState({ sidebarCollapsed: this.lastLgSidebarCollapsed });
    }
  }

  updateNavbarsPositions() {
    // eslint-disable-next-line react/no-find-dom-node
    const containerElement = ReactDOM.findDOMNode(
      this.containerRef.current
    ) as any;
    if (containerElement) {
      const navbarElements = containerElement.querySelectorAll(
        ':scope .layout__navbar'
      );

      // Calculate and update style.top of each navbar
      let totalNavbarsHeight = 0;
      navbarElements.forEach((navbarElement: any) => {
        const navbarBox = navbarElement.getBoundingClientRect();
        navbarElement.style.top = `${totalNavbarsHeight}px`;
        totalNavbarsHeight += navbarBox.height;
      });
    }
  }

  toggleSidebar(event: any) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    this.setState({
      sidebarCollapsed: !this.state.sidebarCollapsed,
    });
  }

  setElementsVisibility(elements: any) {
    this.setState(
      _.pick(elements, ['sidebarHidden', 'navbarHidden', 'footerHidden'])
    );
  }

  updateCrumbs(crumbs: Array<{ name: string; path: string; state?: Record<string, any> }>) {
    if (!_.isEqual(crumbs, this.state.crumbs)) {
      this.setState({
        crumbs,
      });
    }
  }

  render() {
    const { children, favIcons } = this.props;
    const sidebar = findChildByType(children, LayoutSidebar) as any;
    const navbars = findChildrenByType(children, LayoutNavbar);
    const content = findChildByType(children, LayoutContent);
    const otherChildren = _.differenceBy(
      React.Children.toArray(children),
      [sidebar, ...navbars, content],
      'type'
    );
    const layoutClass = classNames('layout', 'layout--animations-enabled', {
      //'layout--only-navbar': this.state.sidebarHidden && !this.state.navbarHidden
    });

    return (
      <HelmetProvider>
        <PageConfigContext.Provider
          value={{
            ...this.state,
            sidebarSlim:
              !!this.props.sidebarSlim &&
              (this.state.screenSize === 'lg' ||
                this.state.screenSize === 'xl'),

            toggleSidebar: this.toggleSidebar.bind(this),
            setElementsVisibility: this.setElementsVisibility.bind(this),
            updateCrumbs: this.updateCrumbs.bind(this),
            changeMeta: (metaData: any) => {
              this.setState(metaData);
            },
          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {this.props.pageTilte ||
                config.siteTitle +
                  (this.state.pageTitle ? ` - ${this.state.pageTitle}` : '')}
            </title>
            <link rel="canonical" href={config.siteCannonicalUrl} />
            <meta name="description" content={this.state.pageDescription} />
            {_.map(favIcons, (favIcon, index) => (
              <link {...favIcon} key={index} />
            ))}
          </Helmet>
          <ThemeClass>
            {(themeClass: any) => (
              <div
                className={classNames(layoutClass, themeClass)}
                ref={this.containerRef}
              >
                {!this.state.sidebarHidden &&
                  sidebar &&
                  React.cloneElement(sidebar, {
                    sidebarSlim:
                      !!this.props.sidebarSlim &&
                      this.state.sidebarCollapsed &&
                      (this.state.screenSize === 'lg' ||
                        this.state.screenSize === 'xl'),
                    sidebarCollapsed:
                      !this.props.sidebarSlim && this.state.sidebarCollapsed,
                  })}

                <div className="layout__wrap">
                  {!this.state.navbarHidden && navbars}

                  {content}
                </div>

                {otherChildren}
              </div>
            )}
          </ThemeClass>
        </PageConfigContext.Provider>
      </HelmetProvider>
    );
  }
}

const routedLayout = withRouter(Layout);

export { routedLayout as Layout };
