import React from 'react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CustomInput,
  FormGroup,
  FormText,
  Label,
} from '@zerintia/powerstone-ui';

interface ZCheckboxControllerProps {
  name: string;
  defaultValue?: boolean;
  label: string;
  onChange?: Function;
}

const ZCheckbox: React.FC<ZCheckboxControllerProps> = ({
  name,
  label,
  onChange = () => {},
  defaultValue,
  ...rest
}) => {
  const { errors } = useFormContext();

  return (
    <>
      <FormGroup check inline>
        <Controller
          name={name}
          defaultValue={defaultValue || false}
          render={({ onChange: onFormChange, value }) => {
            return (
              <CustomInput
                {...rest}
                inline
                invalid={Boolean(get(errors, name))}
                value={value}
                onChange={(ev: any) => {
                  onFormChange(ev.target.checked);
                  onChange(ev);
                  return ev;
                }}
                defaultChecked={defaultValue}
                errors={errors[name]}
                name={name}
                id={name}
                type="checkbox"
              />
            );
          }}
        />
        <Label htmlFor={name} check>
          {label}
        </Label>
      </FormGroup>
      {get(errors, name) && (
        <FormText color="danger">{get(errors, `${name}.message`)}</FormText>
      )}
    </>
  );
};

export default ZCheckbox;
